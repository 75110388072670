import type { NovaPoshtaWarehouseType, NovaPoshtaCity, NovaPoshtaStreet, NovaPoshtaWarehouse } from 'src/models/novaPoshta'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId } from 'src/types'

export type NovaPoshtaResponse<T> = {
  data: T[]
  errorCodes: unknown[]
  errors: unknown[]
  info: {
    totalCount: number
  }
  infoCodes: unknown[]
  messageCodes: unknown[]
  success: boolean
  warningCodes: unknown[]
  warnings: unknown[]
}

export type NovaPoshtaGetAddressParams = {
  q: string
  cityRef: NovaPoshtaCity['ref']
  warehouseType: NovaPoshtaWarehouseType
}

export type NovaPoshtaGetCitiesParams = {
  q: string
}

export type NovaPoshtaGetStreetsParams = {
  q: string
  cityRef: NovaPoshtaCity['ref']
}

export default class NovaPoshtaModule extends BaseModule {
  async getAddress(params: NovaPoshtaGetAddressParams) {
    const res = await this.client.get<NovaPoshtaResponse<NovaPoshtaWarehouse>>('/nova_poshta/address/', { params })
    return res.data
  }

  async getCities(params: NovaPoshtaGetCitiesParams) {
    const res = await this.client.get<NovaPoshtaResponse<NovaPoshtaCity>>('/nova_poshta/cities/', { params })
    return res.data
  }

  async getStreets(params: NovaPoshtaGetStreetsParams) {
    const res = await this.client.get<NovaPoshtaResponse<NovaPoshtaStreet>>('/nova_poshta/streets/', { params })
    return res.data
  }

  static key = 'novaPoshta' as const

  static queryKeys = createQueryKeys(this.key, {
    getAddress: (params: MaybeRef<NovaPoshtaGetAddressParams>, locale: MaybeRef<LocaleId>) => [{ params, locale }],
    getCities: (params: MaybeRef<NovaPoshtaGetCitiesParams>, locale: MaybeRef<LocaleId>) => [{ params, locale }],
    getStreets: (params: MaybeRef<NovaPoshtaGetStreetsParams>, locale: MaybeRef<LocaleId>) => [{ params, locale }],
  })
}
