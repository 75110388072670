import type { Nullable } from 'src/types'

export function toSafeNumber(value: Nullable<number | string>) {
  return (typeof value === 'string' ? parseFloat(value) : value) || 0
}

export function formatNumberToTwoDigits(value: number): string {
  if (value < 10) return `0${value}`
  return String(value)
}
