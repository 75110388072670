/* eslint-disable */
/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.config.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import iconSet from 'quasar/icon-set/fontawesome-v6'



import {Meta,Notify,Dialog,Dark,Cookies,LoadingBar} from 'quasar'



export default { config: {"lang":{"noHtmlAttrs":false}},iconSet,plugins: {Meta,Notify,Dialog,Dark,Cookies,LoadingBar} }

