import type ApiService from 'src/services/api.service'
import { mergeQueryKeys } from '@lukemorales/query-key-factory'
import AuthModule from './modules/auth.module'
import AccountModule from './modules/account.module'
import BlogModule from './modules/blog.module'
import CartModule from './modules/cart.module'
import CatalogModule from './modules/catalog.module'
import ContentModule from './modules/content.module'
import DeliveryModule from './modules/delivery.module'
import FlatpagesModule from './modules/flatpages.module'
import NovaPoshtaModule from './modules/novaPoshta.module'
import OrderModule from './modules/order.module'
import ProductModule from './modules/product.module'
import ReportsModule from './modules/reports.module'
import SiteModule from './modules/site.module'
import TokensModule from './modules/tokens.module'
import { CodeModule } from './modules/code.module'
import FeedbacksModule from './modules/feedbacks.module'
import CommentsModule from './modules/comments.module'
import { PromotionsModule } from './modules/promotions.module'
import { BannersModule } from './modules/banners.module'

export const createApi = (client: ApiService) => ({
  [AuthModule.key]: new AuthModule(client),
  [AccountModule.key]: new AccountModule(client),
  [BlogModule.key]:  new BlogModule(client),
  [CartModule.key]: new CartModule(client),
  [CatalogModule.key]: new CatalogModule(client),
  [ContentModule.key]: new ContentModule(client),
  [DeliveryModule.key]: new DeliveryModule(client),
  [FlatpagesModule.key]: new FlatpagesModule(client),
  [NovaPoshtaModule.key]: new NovaPoshtaModule(client),
  [OrderModule.key]: new OrderModule(client),
  [ProductModule.key]: new ProductModule(client),
  [ReportsModule.key]: new ReportsModule(client),
  [SiteModule.key]: new SiteModule(client),
  [TokensModule.key]: new TokensModule(client),
  [CodeModule.key]: new CodeModule(client),
  [FeedbacksModule.key]: new FeedbacksModule(client),
  [CommentsModule.key]: new CommentsModule(client),
  [PromotionsModule.key]: new PromotionsModule(client),
  [BannersModule.key]: new BannersModule(client)
})

export const queryKeys = mergeQueryKeys(
  ProductModule.queryKeys,
  FlatpagesModule.queryKeys,
  CatalogModule.queryKeys,
  BlogModule.queryKeys,
  AccountModule.queryKeys,
  CartModule.queryKeys,
  ContentModule.queryKeys,
  DeliveryModule.queryKeys,
  OrderModule.queryKeys,
  SiteModule.queryKeys,
  FeedbacksModule.queryKeys,
  CommentsModule.queryKeys,
  PromotionsModule.queryKeys,
  NovaPoshtaModule.queryKeys,
  BannersModule.queryKeys
)

export type Api = ReturnType<typeof createApi>

export type QueryKeys = typeof queryKeys
