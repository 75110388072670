import type { PaginationResponse, PaginationDTO, BonusHistory, LocaleId } from 'src/types'
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'
import type { UserInfoData } from 'src/models/account/userInfo'
import type { OrderData, OrderId } from 'src/models/order'
import type { OrderListData } from 'src/models/order/previewItem'
import type { UserSettings } from 'src/models/account/user'
import type { ProductRef } from 'src/models/product'
import type { MaybeRef } from 'vue'
import type { ReviewComment, QuestionComment } from 'src/types'
import type { UserDeliveryAddress, UserDeliveryAddressInput } from 'src/models/account/deliveryAddresses'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'

export type ChangePasswordPayload = {
  oldPassword: string
  password: string
  confirmedPassword: string
}

export default class AccountModule extends BaseModule {
  async getPreorders() {
    return this.client.get('/accounts/preorders/', { requiresAuth: true })
  }

  async preorderProduct(payload: ProductRef) {
    return this.client.post('/accounts/preorders/', payload, { requiresAuth: true })
  }

  async getUserInfo() {
    return this.client.get<UserInfoData>('/accounts/userinfo/', { requiresAuth: true })
  }

  async getUserSettings() {
    return this.client.get<UserSettings>('/accounts/settings/', { requiresAuth: true })
  }

  async changeUserSettings(payload: UserSettings) {
    return this.client.put<UserSettings>('/accounts/settings/', payload, { requiresAuth: true })
  }

  async getComments() {
    return this.client.get<ReviewComment[]>('/accounts/comments/', { requiresAuth: true })
  }

  async getQuestions() {
    return this.client.get<QuestionComment[]>('/accounts/qas/', { requiresAuth: true })
  }

  async getOrders(params?: PaginationDTO) {
    const data = await this.client.get<OrderListData>('/accounts/orders/', { params, requiresAuth: true })
    return data.results
  }

  async getOrder(orderId: OrderId) {
    return this.client.get<OrderData>(`/accounts/orders/${orderId}/`, { requiresAuth: true })
  }

  async changePassword(payload: ChangePasswordPayload) {
    return this.client.put('/accounts/change_password/', payload, { requiresAuth: true })
  }

  async addToWishlist(payload: ProductRef) {
    await this.client.post('/accounts/lists/products/', payload, { requiresAuth: true })
  }

  async removeFromWishlist(payload?: ProductRef) {
    await this.client.delete('/accounts/lists/products/', { data: payload, requiresAuth: true })
  }

  async getDeliveryAddresses() {
    return this.client.get<UserDeliveryAddress[]>('/accounts/delivery_addresses/', { requiresAuth: true })
  }

  async createDeliveryAddress(payload: UserDeliveryAddressInput) {
    return this.client.post<UserDeliveryAddress>('/accounts/delivery_addresses/', payload, { requiresAuth: true })
  }

  async changeDeliveryAddress(id: UserDeliveryAddress['id'], payload: UserDeliveryAddressInput) {
    return this.client.put<UserDeliveryAddress>(`/accounts/delivery_addresses/${id}/`, payload, { requiresAuth: true })
  }

  async deleteDeliveryAddress(id: UserDeliveryAddress['id']) {
    return this.client.delete(`/accounts/delivery_addresses/${id}/`, { requiresAuth: true })
  }

  public connectSocialProvider(data: { provider: SocialProvider } & LoginSocialProviderResponse) {
    return this.client.post('/accounts/social/', data, { requiresAuth: true })
  }

  public disconnectSocialProvider(data: { provider: SocialProvider }) {
    return this.client.delete('/accounts/social/', { data, requiresAuth: true })
  }

  public async getBonusesHistory(params?: PaginationDTO) {
    return (await this.client.get<{
      results: PaginationResponse<BonusHistory>
    }>('/accounts/bonuses/', { params, requiresAuth: true })).results
  }

  static key = 'account' as const

  static queryKeys = createQueryKeys(this.key, {
    getUserInfo: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getUserSettings: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getComments: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getQuestions: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getOrders: (
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
    getOrder: (
      orderId: MaybeRef<OrderId | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ orderId, locale }],
    getBonusesHistory: (
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
    getDeliveryAddresses: (locale: MaybeRef<LocaleId>) => [{ locale }],
  })
}
