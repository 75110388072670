import { DEFAULT_LOCALE, LOCALES } from 'src/config'
import type { ExternalLocale, Locale, LocaleId } from 'src/types'
import type { PluralizationRule } from 'vue-i18n'

export const slavicLangPluralizationRule: PluralizationRule = function (choice, choicesLength) {
  if (choice === 0) return 0

  const teen = choice > 10 && choice < 20
  const endsWithOne = choice % 10 === 1

  if (!teen && endsWithOne) return 1

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) return 2

  return choicesLength < 4 ? 2 : 3
}

export function isDefaultLocale(id: LocaleId): boolean {
  return id === DEFAULT_LOCALE.id
}

export function isSupportedLocale(id: LocaleId): boolean {
  return LOCALES.map(({ id }) => id).includes(id)
}

export function getLocaleById(id: LocaleId): Locale {
  if (!isSupportedLocale(id)) return DEFAULT_LOCALE
  return LOCALES.find((locale) => locale.id === id) as Locale
}

export function localeToRouteLocale(id: LocaleId): LocaleId | '' {
  if (isDefaultLocale(id) || !isSupportedLocale(id)) return ''
  return id
}

export function isExternalLocale(locale: Locale | ExternalLocale): locale is ExternalLocale {
  const url: keyof ExternalLocale = 'url'
  return url in locale
}
