import type { SeoMeta } from 'src/types'
import type { MetaOptions } from 'quasar/dist/types/meta'
import { computed, toValue, type MaybeRefOrGetter } from 'vue'
import { escapeHTML } from 'src/utils/html'
import { mapValues } from 'lodash'

export function useNormalizeSeoMeta(seoMeta: MaybeRefOrGetter<SeoMeta>) {
  return computed<MetaOptions>(() => {
    const _seoMeta = toValue(seoMeta)

    const { title, description, keywords, image } = mapValues(_seoMeta, escapeHTML)

    return {
      title,
      meta: {
        ogTitle: {
          property: 'og:title',
          content: title
        },
        ogImage: {
          property: 'og:image',
          content: image,
        },
        ogImageAlt: {
          property: 'og:image:alt',
          content: title,
        },
        description: {
          name: 'description',
          content: description
        },
        ogDescription: {
          property: 'og:description',
          content: description,
        },
        keywords: {
          name: 'keywords',
          content: keywords
        },
        twitterTitle: {
          name: 'twitter:title',
          content: title,
        },
        twitterDescription: {
          name: 'twitter:description',
          content: description,
        },
        twitterCard: {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        twitterImage: {
          name: 'twitter:image',
          content: image,
        },
        twitterImageAlt: {
          name: 'twitter:image:alt',
          content: title,
        },
      },
    }
  })
}
