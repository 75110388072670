import { useQuery } from 'src/composables'
import type { MaybeRefOrGetter } from 'vue'

export function useApiAccountGetUserInfoQuery(
  options?: Partial<{ enabled: MaybeRefOrGetter<boolean> }>
) {
  return useQuery({
    queryKey: ({ queryKeys, locale }) => queryKeys.account.getUserInfo(locale).queryKey,
    queryFn: ({ api }) => api.account.getUserInfo(),
    enabled: options?.enabled,
    requiresAuth: true
  })
}
