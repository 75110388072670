import type CookieService from 'src/services/cookie.service'
import { INJECTION_KEY } from 'src/boot/cookies'
import { inject } from 'vue'

export function useCookies() {
  const cookies = inject<CookieService>(INJECTION_KEY)

  if (!cookies) {
    throw new Error('Cookies is not available. Make sure to provide the Cookies through injection.')
  }

  return cookies
}
