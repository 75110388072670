import { isFileUrl } from './isFileUrl'

export function normalizeRelativeUrl(url: string): string {
  // Удаляем все повторяющиеся слэши в URL
  url = url.replace(/\/{2,}/g, '/')

  // Добавляем начальный слэш, если его нет
  if (!url.startsWith('/')) {
    url = '/' + url
  }

  // Проверяем наличие query параметров или анкора в конце URL
  const hasQueryOrAnchor = /[?#]/.test(url)

  // Добавляем конечный слэш, если его нет, это не ссылка на файл
  // и нет query параметров или анкора в конце URL
  if (!url.endsWith('/') && !isFileUrl(url) && !hasQueryOrAnchor) {
    url += '/'
  }

  return url
}
