import type { ProductData, ProductSlug } from 'src/models/product'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { CategorySlug } from 'src/models/catalog/category'
import type { QueryParams } from 'src/types/api'
import type { ProductExternalTag } from 'src/types'
import type { MaybeRef } from 'vue'

export type GetProductParams = {
  slug: ProductSlug
  category?: CategorySlug
}

export default class ProductModule extends BaseModule {
  async getProduct({ slug, category }: GetProductParams) {
    return this.client.get<ProductData>(`/p/${slug}/`, { params: { category } })
  }

  async getExternalTags() {
    return this.client.get<ProductExternalTag[]>('/external_tags/')
  }

  static key = 'product' as const

  static queryKeys = createQueryKeys(this.key, {
    getProduct: (params: QueryParams<GetProductParams>) => [params],
    getExternalTags: (locale: MaybeRef<string>) => [{ locale }]
  })
}
