import { isString } from 'lodash'
import type { ProductData } from './../../models/product/index'
import type { CartData } from 'src/models/cart'
import type { PaymentMethod } from 'src/models/payment'
import type { Merge } from 'type-fest'
import type { ProductRef } from 'src/models/product/index'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { LocaleId, Nullable } from 'src/types'
import type { DeliveryTechnology, TransportCompany } from 'src/models/delivery'
import type { NovaPoshtaWarehouse, NovaPoshtaCity, NovaPoshtaStreet } from 'src/models/novaPoshta'
import type { OrderId } from 'src/models/order'
import type { UserDeliveryAddress } from 'src/models/account/deliveryAddresses'
import type { MaybeRef } from 'vue'

export type CartMutationPayload = Merge<ProductRef, {
  count?: number
  comment?: string
}>

export type CartApprovePayload = {
  phone: string | undefined
  firstName: string | undefined
  secondName: string | undefined
  lastName: string | undefined

  recipientPhone: string | undefined
  recipientFirstName: string | undefined
  recipientSecondName: string | undefined
  recipientLastName: string | undefined

  email: string | undefined
  comment: string | undefined
  doNotCallBack: boolean | undefined
  transportCompany: TransportCompany['id'] | undefined
  paymentMethod: PaymentMethod['id'] | undefined
  selfPickUp: boolean | undefined

  delivery: {
    novaPoshtaWarehouse: NovaPoshtaWarehouse['ref'] | undefined
    technology: DeliveryTechnology['id'] | undefined
    city: NovaPoshtaCity['ref'] | undefined
    street: NovaPoshtaStreet['ref'] | undefined
    house: string | undefined
    apartment: string | undefined
    id: UserDeliveryAddress['id'] | undefined
  }

  promocode: string | undefined
  bonuses: number | undefined
}

export type CartApproveResponse = {
  orderId: OrderId,
  paymentUrl?: {
    url?: string
  }
}

export type ApplyBonusesPayload = {
  bonuses: number
}

type CartPromoItemsResponse = {
  groupInfo: Partial<{
    description: string
    url: string
  }>,
  products: ProductData[]
}

export default class CartModule extends BaseModule {
  async getCart() {
    return this.client.get<CartData>('/cart/', { requiresAuth: true, requiresUserKey: true })
  }

  async addItem(payload: CartMutationPayload) {
    return this.client.post<CartData>('/cart/', payload, { requiresAuth: true, requiresUserKey: true })
  }

  async changeItem(payload: CartMutationPayload) {
    return this.client.put<CartData>('/cart/', payload, { requiresAuth: true, requiresUserKey: true })
  }

  async removeItems(payload: CartMutationPayload[]) {
    return this.client.delete<CartData>('/cart/', {
      data: payload,
      requiresAuth: true,
      requiresUserKey: true
    })
  }

  async getPayments() {
    return this.client.get<PaymentMethod[]>('/cart/payments/')
  }

  async approve(payload: CartApprovePayload, params: Record<string, string>) {
    return this.client.post<CartApproveResponse>('/cart/approve/', payload, { params, requiresAuth: true })
  }

  async verify() {
    return this.client.get<unknown>('/cart/verify/', { requiresAuth: true, requiresUserKey: true })
  }

  async getAvailableBonuses() {
    const { available } = await this.client.post<{ available: Nullable<number> }>('/bonuses/validate/', undefined, { requiresAuth: true })
    return available ?? 0
  }

  async applyBonuses(payload: ApplyBonusesPayload) {
    return this.client.post<CartData>('/bonuses/apply/', payload, { requiresAuth: true })
  }

  async getPromoItems(): Promise<CartPromoItemsResponse> {
    const res = await this.client.get<CartPromoItemsResponse | string>('/cart/promo/items/', { requiresAuth: true, requiresUserKey: true })
    if (isString(res)) return { groupInfo: {}, products: [] }
    return res
  }

  static key = 'cart' as const

  static queryKeys = createQueryKeys(this.key, {
    getCart: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getPayments: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getAvailableBonuses: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getPromoItems: (locale: MaybeRef<LocaleId>) => [{ locale }],
  })
}
