import { route } from 'quasar/wrappers'
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router'

import routes from './routes'
import { useStateStore } from 'src/stores/state'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store }) {
  const stateStore = useStateStore(store)

  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: (to, from, savedPosition) => {
      if (stateStore.preventScrollBehavior) {
        stateStore.preventScrollBehavior = false
        return
      }

      const changedLocale = from.params.locale !== to.params.locale
      const sameName = from.name === to.name
      const toHash = to.hash
      const toTab = to.query.tab

      if (toHash && sameName) return { el: toHash, behavior: 'smooth' }
      if (toTab && sameName) return { el: `#${toTab}`, behavior: 'smooth' }
      if (changedLocale) return
      if (sameName) return { top: 0, behavior: 'smooth' }
      if (toHash) return { el: toHash }
      if (savedPosition) return { top: savedPosition.top }
      return { top: 0 }
    },
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  return Router
})
