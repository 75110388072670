import type { Feedback, FeedbackId, FeedbackThemeId, FeedbackSlug } from 'src/models/feedback.model'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import BaseModule from './base.module'
import type { LocaleId } from 'src/types'

export type CreateFeedbackPayload = {
  form: FeedbackId
  firstName: string
  lastName: string
  phone: string
  email: string
  orgName: string
  body: string
  theme: FeedbackThemeId
}

export default class FeedbacksModule extends BaseModule {
  async createFeedback(payload: CreateFeedbackPayload) {
    return this.client.post('/feedbacks/create/', payload)
  }

  async getFeedback(feedbackSlug: FeedbackSlug) {
    return this.client.get<Feedback>(`/feedbacks/${feedbackSlug}/`)
  }

  static key = 'feedbacks' as const

  static queryKeys = createQueryKeys(this.key, {
    getFeedback: (
      feedbackSlug: MaybeRef<FeedbackSlug | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ feedbackSlug, locale }],
  })
}
