import type { LoginSocialProviderResponse } from 'src/types'
import { useAccount } from 'src/composables/account'
import { SocialProvider } from 'src/types'
import { onMounted } from 'vue'
import { googleOneTap, decodeCredential } from 'vue3-google-login'
import { useLogin } from './useLogin'
import { useApiAuthLoginBySocialProviderMutation } from 'src/composables/api/auth'
import { useEventBus } from 'src/composables/eventBus'

export function useGoogleOneTapLogin() {
  const login = useLogin()
  const loginWithSocialProviderMutation = useApiAuthLoginBySocialProviderMutation()
  const eventBus = useEventBus()
  const { isAuth } = useAccount()

  onMounted(loginHandler)

  async function loginHandler() {
    if (isAuth.value) return

    const response = await googleOneTap()

    const data = decodeCredential(response.credential) as {
      sub: string
      given_name: string
      family_name: string
      email: string
    }

    const normalizedData: LoginSocialProviderResponse = {
      code: data.sub,
      firstName: data.given_name,
      lastName: data.family_name,
      email: data.email
    }

    try {
      const { tokenSet } = await loginWithSocialProviderMutation.mutateAsync({
        provider: SocialProvider.GOOGLE,
        ...normalizedData
      })

      if (tokenSet) {
        await login(tokenSet)
      } else {
        eventBus.emit('googleOneTapLoginApprove', normalizedData)
      }
    } catch (e) {
      eventBus.emit('googleOneTapLoginApprove', normalizedData)
    }
  }
}
