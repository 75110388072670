import type { ProductSlug, ProductRef } from 'src/models/product'
import type { MaybeRef } from 'vue'
import type {
  PaginationResponse,
  PaginationDTO,
  QuestionComment,
  ReviewComment,
  ReviewCommentImage,
  ReviewCommentImageId,
  LocaleId
} from 'src/types'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'

type CreateCommentDTO<T> = ProductRef & {
  comment: string
  userName?: string
  userEmail?: string
  reply?: T
}

type CreateQuestionCommentDTO = CreateCommentDTO<QuestionComment['id']>

type CreateReviewCommentDTO = CreateCommentDTO<ReviewComment['id']> & {
  rating?: number
  virtues?: string
  limitations?: string
  uploadedImages?: File | FileList
}

export default class CommentsModule extends BaseModule {
  createReviewComment(payload: CreateReviewCommentDTO) {
    return this.client.post('/comments/', payload, {
      requiresAuth: true,
      headers: { 'Content-type': 'multipart/form-data' },
    })
  }

  async getReviewComments(productSlug: ProductSlug, params?: PaginationDTO) {
    return (await this.client.get<{
      results: PaginationResponse<ReviewComment>
    }>(`/comments/${productSlug}/`, { params })).results
  }

  getReviewCommentByImageId(id: ReviewCommentImageId) {
    return this.client.get<ReviewComment>(`/comments/images/${id}/`)
  }

  getReviewImages(productSlug: ProductSlug) {
    return this.client.get<ReviewCommentImage[]>(`/comments/${productSlug}/images/`)
  }

  createQuestionComment(payload: CreateQuestionCommentDTO) {
    return this.client.post('/qas/', payload, { requiresAuth: true })
  }

  getQuestionComments(productSlug: ProductSlug) {
    return this.client.get<QuestionComment[]>(`/qas/${productSlug}/`)
  }

  static key = 'comments' as const

  static queryKeys = createQueryKeys(this.key, {
    getReviewComments: (
      productSlug: MaybeRef<ProductSlug | undefined>,
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ productSlug, params, locale }],

    getReviewCommentByImageId: (
      id: MaybeRef<ReviewCommentImageId | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ id, locale }],

    getReviewImages: (
      productSlug: MaybeRef<ProductSlug | undefined>
    ) => [productSlug],

    getQuestionComments: (
      productSlug: MaybeRef<ProductSlug | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ productSlug, locale }]
  })
}
