import type { AccessToken, RefreshToken, TokenSet } from 'src/types/token'
import BaseModule from './base.module'

export default class TokensModule extends BaseModule {
  async verifyToken(token: AccessToken | RefreshToken): Promise<boolean> {
    try {
      await this.client.post('/token_verify/', { token }, { skipRetry: true })
      return true
    } catch (error) {
      return false
    }
  }

  public async getRefreshedTokens(token: RefreshToken) {
    return this.client.post<TokenSet>('/refresh/', { refresh: token }, { skipRetry: true })
  }

  static key = 'tokens' as const
}
