import { uid } from 'quasar'

export abstract class Model<D = unknown> {
  public data!: D
  public uid: string

  constructor(data: D) {
    this.setData(data)
    this.uid = uid()
  }

  public setData(data: D) {
    this.data = data
  }
}
