import { cloneDeep } from 'lodash'
import type { MaybeRefOrGetter } from 'vue'
import { computed, toValue } from 'vue'
import type { Class } from 'type-fest'
import type { Model } from 'src/models/model'
import type { Nullable } from 'src/types'

export function useModel<D, M extends Model<D>>(
  modelClass: Class<M, [D]>,
  modelData: MaybeRefOrGetter<Nullable<D>>
) {
  return computed(() => {
    const data = cloneDeep(toValue(modelData))
    return data ? new modelClass(data) : undefined
  })
}
