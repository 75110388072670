// import { useEsputnik } from './useEsputnik'
import { useGtm } from './useGtm'
import { usePixel } from './usePixel'
import { useAdmitad } from './useAdmitad'

export function useMetrics() {
  useGtm()
  usePixel()
  useAdmitad()
}
