import { boot } from 'quasar/wrappers'
import CookieService from 'src/services/cookie.service'
import { Cookies } from 'quasar'
import type { InjectionKey } from 'vue'

export const INJECTION_KEY = Symbol('cookies') as InjectionKey<CookieService>

declare module 'vue' {
  interface ComponentCustomProperties {
    $cookies: CookieService
  }
}

export default boot(({ app, ssrContext }) => {
  const cookiesPlugin = process.env.SERVER
    ? Cookies.parseSSR(ssrContext)
    : Cookies

  const cookiesService = new CookieService(cookiesPlugin)

  app.config.globalProperties.$cookies = cookiesService
  app.provide(INJECTION_KEY, cookiesService)
})
