import type { BlogData } from 'src/models/blog'
import type { BlogPostData, BlogPostSlug } from 'src/models/blog/post'
import type { BlogGroupSlug, BlogGroupListData } from 'src/models/blog/group'
import type { LocaleId, PaginationDTO } from 'src/types'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'

export default class BlogModule extends BaseModule {
  async getPost(slug: BlogPostSlug) {
    return this.client.get<BlogPostData>(`/news/${slug}/`)
  }

  async getBlog(params?: PaginationDTO) {
    return this.client.get<BlogData>('/news/', { params })
  }

  async getGroups() {
    return this.client.get<BlogGroupListData>('/news/groups/')
  }

  async getGroup(slug: BlogGroupSlug, params?: PaginationDTO) {
    return this.client.get<BlogData>(`/news/groups/${slug}/`, { params })
  }

  async getTag(id: string) {
    return this.client.get<BlogData>(`/news/tags/${id}/`)
  }

  static key = 'blog' as const

  static queryKeys = createQueryKeys(this.key, {
    getBlog: (
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
    getPost: (
      slug: MaybeRef<BlogPostSlug | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ slug, locale }],
    getGroups: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getGroup: (
      slug: MaybeRef<BlogGroupSlug | undefined>,
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ slug, params, locale }]
  })
}
