import { Model } from 'src/models/model'
import type { ProductId } from 'src/models/product/index'
import type { NullableKeys, SocialProvider } from 'src/types'
import { toSafeNumber } from 'src/utils/number'
import { formatPrice } from 'src/utils/price'
import { formatFullPhoneNumber } from 'src/utils/strings'

export type UserBonusesData = NullableKeys<{
  total: string | number
  used: string | number
  waiting: string | number
}>

export type UserInfoData = {
  bonuses: UserBonusesData
} & Partial<{
  currencyCharCode: string
  debt: unknown
  email: string
  excahngeRate: unknown
  firstName: string | null
  lastName: string | null
  secondName: string | null
  manager: string
  ordersInfo: unknown
  perPage: number
  userPriceFieldName: string
  preordered: ProductId[]
  username: string
  selectedProducts: ProductId[]
  viewedProducts: ProductId[]
  demoAccess: boolean
  sellerVendor: boolean
  providers: SocialProvider[]
}>

export class UserBonuses extends Model<UserBonusesData> {
  constructor(data: UserBonusesData) {
    super(data)
  }

  get total() {
    return toSafeNumber(this.data.total)
  }

  get totalFormatted() {
    return formatPrice(this.total)
  }

  get used() {
    return toSafeNumber(this.data.used)
  }

  get usedFormatted() {
    return formatPrice(this.used)
  }

  get waiting() {
    return toSafeNumber(this.data.waiting)
  }

  get waitingFormatted() {
    return formatPrice(this.waiting)
  }
 }

export class UserInfo extends Model<UserInfoData> {
  constructor(data: UserInfoData) {
    super(data)
  }

  get firstName() {
    return this.data.firstName ?? undefined
  }

  get lastName() {
    return this.data.lastName ?? undefined
  }

  get secondName() {
    return this.data.secondName ?? undefined
  }

  get fullName() {
    const lastName = this.lastName ? ` ${this.lastName}` : ''
    return `${this.firstName}${lastName}`
  }

  get email() {
    return this.data.email || undefined
  }

  get username() {
    return this.data.username ?? ''
  }

  get usernameDisplay() {
    return formatFullPhoneNumber(this.username)
  }

  get isDemoAccess() {
    return this.data.demoAccess ?? false
  }

  get wishlistProducts(): ProductId[] {
    return this.data.selectedProducts ?? []
  }

  get viewedProducts(): ProductId[] {
    return this.data.viewedProducts ?? []
  }

  get preorderedProducts() {
    return this.data.preordered ?? []
  }

  get bonuses() {
    return new UserBonuses(this.data.bonuses)
  }

  get isSeller() {
    return this.data.sellerVendor ?? false
  }

  get connectedSocialProviders() {
    return this.data.providers ?? []
  }
}
