import type { MaybeRefOrGetter, WritableComputedRef } from 'vue'
import type { QueryKey, QueryFunctionContext, UseQueryReturnType } from '@tanstack/vue-query'
import type { DeepUnwrapRef, MaybeRefDeep } from '@tanstack/vue-query/src/types'
import type { LocaleId } from 'src/types'
import type { queryKeys as _queryKeys } from 'src/api'
import type { SetFieldType, SetOptional, SetRequired } from 'type-fest'
import { queryKeys } from 'src/api'
import { toValue, computed } from 'vue'
import { useQuery as useQueryFn } from '@tanstack/vue-query'
import { useAuthStore } from 'src/stores/auth'
import { useApi } from 'src/composables'
import { isFunction } from 'lodash'
import { useI18n } from 'src/composables'

export type UseQueryOptions<
  TData,
  TDataTransformed,
  TQueryKey extends QueryKey
> = {
  queryKey: ((ctx: {
    queryKeys: typeof _queryKeys,
    locale: WritableComputedRef<LocaleId>
  }) => MaybeRefDeep<TQueryKey>) | MaybeRefDeep<TQueryKey>,
  queryFn: (ctx: Omit<QueryFunctionContext<DeepUnwrapRef<TQueryKey>, never>, 'pageParam' | 'direction'>
    & { api: ReturnType<typeof useApi> }) => Promise<TData>,
  enabled?: MaybeRefOrGetter<boolean | undefined>,
  requiresAuth?: MaybeRefOrGetter<boolean>,
  requiresUserKey?: MaybeRefOrGetter<boolean>,
  dataTransformer?: (data: TData | undefined) => TDataTransformed,
}

export function useQuery<
  TData = unknown,
  TDataTransformed = unknown,
  TError = Error,
  TQueryKey extends QueryKey = QueryKey
>(
  options: SetOptional<SetFieldType<
    UseQueryOptions<TData, TDataTransformed, TQueryKey>, 'dataTransformer', undefined
  >, 'dataTransformer'>
): UseQueryReturnType<TData, TError>

export function useQuery<
  TData = unknown,
  TDataTransformed = unknown,
  TError = Error,
  TQueryKey extends QueryKey = QueryKey
>(
  options: SetRequired<UseQueryOptions<TData, TDataTransformed, TQueryKey>, 'dataTransformer'>
): UseQueryReturnType<TDataTransformed, TError>

export function useQuery<
  TData = unknown,
  TDataTransformed = unknown,
  TError = Error,
  TQueryKey extends QueryKey = QueryKey,
>(options: UseQueryOptions<TData, TDataTransformed, TQueryKey>) {
  const {
    queryKey,
    queryFn,
    dataTransformer,
    requiresAuth,
    requiresUserKey
  } = options

  const api = useApi()

  const authStore = useAuthStore()

  const { locale } = useI18n()

  const enabled = computed(() => {
    const _enabled = (toValue(options.enabled) ?? true) && !!process.env.CLIENT
    const _requiresAuth = toValue(requiresAuth) ?? false
    const _requiresUserKey = toValue(requiresUserKey) ?? false

    if (requiresAuth || requiresUserKey) {
      return _enabled && ((_requiresAuth && authStore.isAuth) || (_requiresUserKey && !!authStore.userKey))
    }

    return _enabled
  })

  const query = useQueryFn<TData, TError, TData, TQueryKey>({
    queryKey: isFunction(queryKey) ? queryKey({ queryKeys, locale }) : queryKey,
    queryFn: ((ctx) => {
      const { queryKey, meta, signal } = ctx
      return queryFn({ queryKey, meta, signal, api })
    }),
    enabled
  })

  if (isFunction(dataTransformer)) {
    const data = computed(() => dataTransformer(query.data.value))
    return { ...query, data }
  }

  return query
}
