import type { OrderId } from 'src/models/order'
import type { Opaque } from 'type-fest'
import type { ID } from './index'
import type { Component } from 'vue'

export type Pagination = {
  page: number
  numPages: number
  perPage: number
  total: number
}

export type PaginationResponse<T> = {
  hits: T[]
  pagination: Pagination
}

export type PaginationDTO = {
  page?: string
}

export type SeoMeta = Partial<{
  title: string
  description: string
  keywords: string
  image: string
}>

export enum SocialProvider {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE'
}

export type SocialProviderEntity = {
  id: SocialProvider
  title: string
  icon: Component
}

export type LoginSocialProviderResponse = {
  code: string
  email?: string
  firstName?: string
  lastName?: string
}

export type BonusHistory = {
  id: Opaque<number, 'BonusHistoryId'>
  accrued: string
  activationDate: string
  createdAt: string
  document: string
  locked: string
  order: OrderId | null
  updatedAt: string
  user: number
}

export type BannerEntity = {
  id: ID
  lgUrl: string | null
  smUrl: string | null
  position: number
  timeAppear: number
  url: string | null
}
