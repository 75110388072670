import { PHONE_MASK } from 'src/config'

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function isHtml(string: string): boolean {
  const pattern = /<\/?[a-z][\s\S]*>/i
  return pattern.test(string)
}

export function unEscape(htmlString?: string): string | undefined {
  if (!htmlString) return

  htmlString = htmlString.replace(/&lt;/g, '<')
  htmlString = htmlString.replace(/&gt;/g, '>')
  htmlString = htmlString.replace(/&quot;/g, '"')
  htmlString = htmlString.replace(/&#39;/g, "'")
  htmlString = htmlString.replace(/&amp;/g, '&')

  return htmlString
}

export function removeNonDigits(str: string): string {
  return str.replace(/\D+/g, '')
}

function addPrefix(str: string, prefix: string): string {
  if (str.startsWith(prefix)) {
    return str
  } else {
    return `${prefix}${str}`
  }
}

export function removePrefix(str: string, prefix: string): string {
  if (str.startsWith(prefix)) {
    return str.substring(prefix.length)
  } else {
    return str
  }
}

export function formatPhoneNumber(phoneNumber: string, mask = PHONE_MASK): string {
  phoneNumber = removeNonDigits(phoneNumber)
  phoneNumber = removePrefix(phoneNumber, '38')

  let maskedPhoneNumber = ''
  let index = 0

  for (let i = 0; i < mask.length && index < phoneNumber.length; i++) {
    const maskChar = mask[i]
    const phoneChar = phoneNumber[index]

    if (maskChar === '#') {
      maskedPhoneNumber += phoneChar
      index++
    } else {
      maskedPhoneNumber += maskChar
    }
  }

  return maskedPhoneNumber
}

export function getClearPhone(phoneNumber: string) {
  phoneNumber = removeNonDigits(phoneNumber)
  return removePrefix(phoneNumber, '38')
}

export function getClearPhoneWithCode(phoneNumber: string) {
  return addPrefix(removeNonDigits(phoneNumber), '38')
}

export function formatFullPhoneNumber(phoneNumber: string, mask = PHONE_MASK): string {
  return addPrefix(formatPhoneNumber(phoneNumber, mask), '38 ')
}
