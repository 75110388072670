import type { FlatPageData, FlatPageListData } from 'src/models/flatpages'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId } from 'src/types'

export default class FlatpagesModule extends BaseModule {
  async getPage(slug: string) {
    return this.client.get<FlatPageData>(`/flatpages/${slug}/`)
  }

  async getList() {
    return this.client.get<FlatPageListData>('/flatpages/')
  }

  static key = 'flatpages' as const

  static queryKeys = createQueryKeys(this.key, {
    getPage: (
      slug: MaybeRef<string | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ slug, locale }],
    getList: (locale: MaybeRef<LocaleId>) => [{ locale }]
  })
}
