import type { ProductRef } from 'src/models/product'
import type { QueryClient } from '@tanstack/vue-query'
import type { StateStore } from 'src/stores/state'
import type { AuthStore } from 'src/stores/auth'
import type { UserKey } from 'src/types/auth'
import type { Router } from 'vue-router'
import { queryKeys, type Api } from 'src/api'
import RouterService from 'src/services/router.service'
import type { AccessToken, RefreshToken, TokenSet } from 'src/types/token'
import type CookieService from './cookie.service'
import { getTokenExpiresDate } from 'src/utils/jwt'
import log from 'src/utils/logger'
import type { LocaleId } from 'src/types'

export type SetLoginPayload = {
  api: Api,
  router: Router
  queryClient: QueryClient
  viewedProducts?: ProductRef[]
  authStore: AuthStore
  stateStore: StateStore
  cookies: CookieService
  locale: LocaleId
}

export type SetOrRemoveTokensParams = {
  authStore: AuthStore
  cookies: CookieService
}

export type SetLogoutPayload = {
  queryClient: QueryClient,
  authStore: AuthStore
  stateStore: StateStore
  cookies: CookieService
  router: Router
}

export type InitAuthParams = {
  api: Api,
  authStore: AuthStore
  cookies: CookieService
  queryClient: QueryClient
  locale: LocaleId
}

export default class AuthService {
  static async setLogin(tokens: TokenSet, params: SetLoginPayload) {
    const { viewedProducts, api, router, stateStore, queryClient, authStore, cookies, locale } = params

    try {
      this.setTokens(tokens, { authStore, cookies })

      this.invalidateCartQueries(queryClient)

      if (viewedProducts?.length) {
        await api.catalog.postViewedProducts(viewedProducts)
      }

      await this.prefetchAuthQueries({ api, queryClient, locale })

      authStore.setAuth(true)
    } catch (error) {
      await this.setLogout({ queryClient, router, stateStore, authStore, cookies })
      throw error
    }
  }

  static async setLogout({ queryClient, router, stateStore, authStore, cookies }: SetLogoutPayload) {
    if (router.currentRoute.value.meta.requiresAuth) {
      const to = RouterService.getRouteLocation('main', { locale: stateStore.locale })
      await router.push(to)
    }

    this.removeTokens({ authStore, cookies })

    this.removeAccountQueries(queryClient)

    authStore.setAuth(false)

    this.invalidateCartQueries(queryClient)
  }

  static async initAuth({ api, authStore, cookies, queryClient, locale }: InitAuthParams) {
    const tokens = this.getTokensCookies(cookies)
    const userKey = this.getUserKeyCookie(cookies)

    if (userKey) {
      authStore.setUserKey(userKey)
    }

    try {
      if (tokens.access) {
        const isValid = await api.tokens.verifyToken(tokens.access)

        if (isValid) {
          authStore.setTokens(tokens)
          await this.prefetchAuthQueries({ queryClient, api, locale })
          authStore.setAuth(true)
          return
        }
      }

      if (tokens.refresh) {
        const refreshedTokens = await api.tokens.getRefreshedTokens(tokens.refresh)
        this.setTokens(refreshedTokens, { authStore, cookies })
        await this.prefetchAuthQueries({ queryClient, api, locale })
        authStore.setAuth(true)
        return
      }

      this.removeTokensCookies(cookies)
    } catch (error) {
      this.removeTokensCookies(cookies)
      log.error(error)
    }
  }

  static setUserKey(userKey: UserKey, { authStore, cookies }: SetOrRemoveTokensParams) {
    authStore.setUserKey(userKey)
    this.setUserKeyCookie(userKey, cookies)
  }

  static removeUserKey({ authStore, cookies }: SetOrRemoveTokensParams) {
    authStore.removeUserKey()
    this.removeUserKeyCookie(cookies)
  }

  static setTokens(tokens: TokenSet, { authStore, cookies }: SetOrRemoveTokensParams) {
    authStore.setTokens(tokens)
    this.setTokensCookies(tokens, cookies)
  }

  static removeTokens({ authStore, cookies }: SetOrRemoveTokensParams) {
    authStore.removeTokens()
    this.removeTokensCookies(cookies)
  }

  private static invalidateCartQueries(queryClient: QueryClient) {
    queryClient.invalidateQueries({ queryKey: queryKeys.cart._def })
  }

  private static removeAccountQueries(queryClient: QueryClient) {
    queryClient.removeQueries({ queryKey: queryKeys.account._def })
  }

  private static async prefetchAuthQueries(
    { queryClient, api, locale }: { queryClient: QueryClient, api: Api, locale: LocaleId }
  ) {
    await queryClient.prefetchQuery({
      queryKey: queryKeys.account.getUserInfo(locale).queryKey,
      queryFn: () => api.account.getUserInfo()
    })
  }

  private static setUserKeyCookie(userKey: UserKey, cookies: CookieService) {
    const key = this.USER_KEY_COOKIE_KEY
    cookies.set(key, userKey)
  }

  private static getUserKeyCookie(cookies: CookieService) {
    const value = cookies.get(this.USER_KEY_COOKIE_KEY) ?? undefined
    return value as UserKey | undefined
  }

  private static removeUserKeyCookie(cookies: CookieService) {
    cookies.remove(this.USER_KEY_COOKIE_KEY)
  }

  private static setAccessTokenCookie(token: AccessToken, cookies: CookieService) {
    const key = this.ACCESS_TOKEN_COOKIE_KEY
    const expires = getTokenExpiresDate(token)
    cookies.set(key, token, { expires })
  }

  private static setRefreshTokenCookie(token: RefreshToken, cookies: CookieService) {
    const key = this.REFRESH_TOKEN_COOKIE_KEY
    const expires = getTokenExpiresDate(token)
    cookies.set(key, token, { expires })
  }

  private static getAccessTokenCookie(cookies: CookieService) {
    const value = cookies.get(this.ACCESS_TOKEN_COOKIE_KEY) ?? undefined
    return value as AccessToken | undefined
  }

  private static getRefreshTokenCookie(cookies: CookieService) {
    const value = cookies.get(this.REFRESH_TOKEN_COOKIE_KEY) ?? undefined
    return value as RefreshToken | undefined
  }

  private static removeAccessTokenCookie(cookies: CookieService) {
    cookies.remove(this.ACCESS_TOKEN_COOKIE_KEY)
  }

  private static removeRefreshTokenCookie(cookies: CookieService) {
    cookies.remove(this.REFRESH_TOKEN_COOKIE_KEY)
  }

  private static setTokensCookies({ access, refresh }: Partial<TokenSet>, cookies: CookieService) {
    if (access) this.setAccessTokenCookie(access, cookies)
    if (refresh) this.setRefreshTokenCookie(refresh, cookies)
  }

  private static getTokensCookies(cookies: CookieService): Partial<TokenSet> {
    const access = this.getAccessTokenCookie(cookies)
    const refresh = this.getRefreshTokenCookie(cookies)
    return { access, refresh }
  }

  private static removeTokensCookies(cookies: CookieService) {
    this.removeAccessTokenCookie(cookies)
    this.removeRefreshTokenCookie(cookies)
  }

  private static USER_KEY_COOKIE_KEY = 'user_key'

  private static ACCESS_TOKEN_COOKIE_KEY = 'access_token'

  private static REFRESH_TOKEN_COOKIE_KEY = 'refresh_token'
}
