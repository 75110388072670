import { jwtDecode } from 'jwt-decode'
import humps from 'humps'
import type { CamelCasedProperties } from 'type-fest'
import type { UserId } from 'src/types/auth'

export interface DecodedToken {
  exp: number
  iat: number
  jti: string
  token_type: string
  user_id: UserId
}

export function decodeToken(token: string): CamelCasedProperties<DecodedToken> {
  const decoded = jwtDecode<DecodedToken>(token)
  return humps.camelizeKeys(decoded) as CamelCasedProperties<DecodedToken>
}

export function getTokenExpiresDate(token: string): Date {
  const decoded = decodeToken(token)
  return new Date(decoded.exp * 1000)
}
