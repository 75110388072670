import type { TokenSet } from 'src/types/token'
import AuthService from 'src/services/auth.service'
import { useQueryClient } from '@tanstack/vue-query'
import { useApi } from 'src/composables'
import { useViewedProductsStorage } from 'src/composables/catalog/viewedProducts/storage'
import { useRouter } from 'vue-router'
import { useStateStore } from 'src/stores/state'
import { useAuthStore } from 'src/stores/auth'
import { useCookies } from '../cookies'
import { useI18n } from 'src/composables/useI18n'

export function useLogin() {
  const api = useApi()
  const queryClient = useQueryClient()
  const viewedProducts = useViewedProductsStorage()
  const router = useRouter()
  const stateStore = useStateStore()
  const authStore = useAuthStore()
  const cookies = useCookies()
  const { locale } = useI18n()

  return async (tokenSet: TokenSet) => {
    stateStore.isPendingLogin = true

    try {
      await AuthService.setLogin(tokenSet, {
        viewedProducts: viewedProducts.productRefList.value,
        api,
        router,
        stateStore,
        queryClient,
        authStore,
        cookies,
        locale: locale.value
      })
    } finally {
      stateStore.isPendingLogin = false
    }
  }
}
