import type { LocaleId } from 'src/types'
import type { RouteLocationNormalized } from 'vue-router'
import { defineStore } from 'pinia'
import { DEFAULT_LOCALE } from 'src/config'

interface State {
  locale: LocaleId
  isCartOpen: boolean
  isCheckoutOpen: boolean
  showAuthModal: boolean
  preventScrollBehavior: boolean
  previousRoute: RouteLocationNormalized | undefined
  countryCode: string | undefined
  isPendingLogin: boolean
}

export type StateStore = ReturnType<typeof useStateStore>

export const useStateStore = defineStore('stateStore', {
  state: (): State => ({
    locale: DEFAULT_LOCALE.id,
    isCartOpen: false,
    isCheckoutOpen: false,
    isPendingLogin: false,
    showAuthModal: false,
    preventScrollBehavior: false,
    previousRoute: undefined,
    countryCode: undefined,
  }),

  actions: {
    setLocale(locale: LocaleId) {
      this.locale = locale
    },
    showCart() {
      this.isCartOpen = true
    },
    showCheckout() {
      this.showCart()
      this.isCheckoutOpen = true
    }
  }
})
