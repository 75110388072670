import { type MaybeRefOrGetter } from 'vue'
import { useQuery } from 'src/composables'

export function useApiSiteGetInfoQuery(
  options?: Partial<{ enabled: MaybeRefOrGetter<boolean> }>
) {
  return useQuery({
    queryKey: ({ queryKeys, locale }) => queryKeys.site.getInfo(locale).queryKey,
    queryFn: ({ api }) => api.site.getInfo(),
    enabled: options?.enabled,
  })
}
