import type { OrderId } from './../../models/order/index'
import log from 'src/utils/logger'
import { useMeta } from 'quasar'
import { useEventBus } from 'src/composables/eventBus'

declare global {
  interface Window {
    ADMITAD: any
  }
}

type AdmitadProduct = {
  Product: {
    productID: string,  // internal item code (up to 100 characters, matches the ID from the product feed)
    category: string,  // target action rate code
    price: number,  // item price (if there is a discount, this is a discounted price)
    priceCurrency: string,  // currency code per ISO-4217 alpha-3
  },
  orderQuantity: number,  // quantity
  additionalType: 'sale'
}

const CAMPAIGN_CODE = '5318536393'

// name of the cookie that stores the source
// change if you have another name
const COOKIE_NAME = 'deduplication_cookie'

// cookie lifetime
const DAYS_TO_STORE = 90

// expected deduplication_cookie value for Admitad
const DEDUPLICATION_COOKIE_VALUE = 'admitad'

// name of GET parameter for deduplication
// change if you have another name
const CHANNEL_NAME = 'utm_source'

// a function to get the source from the GET parameter
const getSourceParamFromUri = function () {
  let pattern = CHANNEL_NAME + '=([^&]+)'
  let re = new RegExp(pattern)
  return (re.exec(document.location.search) || [])[1] || ''
}

// a function to get the source from the cookie named cookie_name
const getSourceCookie = function () {
  let matches = document.cookie.match(new RegExp(
    // eslint-disable-next-line no-useless-escape
    '(?:^|; )' + COOKIE_NAME.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
  ))
  return matches ? decodeURIComponent(matches[1]) : undefined
}

// a function to set the source in the cookie named cookie_name
const setSourceCookie = function () {
  let param = getSourceParamFromUri()
  let params = new URL(document.location.href).searchParams
  if (!params.get(CHANNEL_NAME) && params.get('gclid')) { param = 'advAutoMarkup' }
  else if (!params.get(CHANNEL_NAME) && params.get('fbclid')) { param = 'facebook' }
  else if (!param) { return }
  let period = DAYS_TO_STORE * 60 * 60 * 24 * 1000 // in seconds
  let expiresDate = new Date((period) + +new Date)
  let cookieString = COOKIE_NAME + '=' + param + '; path=/; expires=' + expiresDate.toUTCString()
  document.cookie = cookieString
  document.cookie = cookieString + '; domain=.' + location.host
}

export function useAdmitad() {
  useMeta({
    script: {
      admitad: {
        src: `https://www.artfut.com/static/tagtag.min.js?campaign_code=${CAMPAIGN_CODE}`,
        async: 'true',
      }
    }
  })

  const onEvent = (event: string, params: { orderId: OrderId, products: AdmitadProduct[] }) => {
    window.ADMITAD = window.ADMITAD || {}
    window.ADMITAD.Invoice = window.ADMITAD.Invoice || {}

    const sourceCookie = getSourceCookie()

    if (!sourceCookie) {
      window.ADMITAD.Invoice.broker = 'na'
    } else if (sourceCookie !== DEDUPLICATION_COOKIE_VALUE) {
      window.ADMITAD.Invoice.broker = sourceCookie
    } else {
      window.ADMITAD.Invoice.broker = 'adm'
    }

    window.ADMITAD.Invoice.category = '1'  // target action code

    window.ADMITAD.Invoice.referencesOrder = window.ADMITAD.Invoice.referencesOrder || []

    // adding more items
    window.ADMITAD.Invoice.referencesOrder.push({
      orderNumber: params.orderId, // order ID from your CMS (up to 100 characters)
      orderedItem: params.products
    })

    // Important! If you send data via AJAX or through the one-click order form, uncomment the last string:
    window.ADMITAD.Tracking.processPositions()

    log.debug(`Admitad: ${event}`, window.ADMITAD)
  }

  if (process.env.CLIENT) {
    // set cookie
    setSourceCookie()

    const eventBus = useEventBus()

    eventBus.on('purchase', (order) => {
      const products = order.productList.items.map<AdmitadProduct>(product => ({
        Product: {
          productID: product.id,
          category: '1',
          price: product.price,
          priceCurrency: 'UAH',
        },
        orderQuantity: product.count,
        additionalType: 'sale'
      }))

      onEvent('purchase', { orderId: order.id, products })
    })

    eventBus.on('oneClickOrder:success', (order) => {
      const products: AdmitadProduct[] = [{
        Product: {
          productID: order.product.id,
          category: '1',
          price: order.product.price,
          priceCurrency: 'UAH',
        },
        orderQuantity: order.productCount,
        additionalType: 'sale'
      }]

      onEvent('oneClickOrder:success', { orderId: order.id, products })
    })
  }
}
