import { INJECTION_KEY } from 'src/boot/api'
import { inject } from 'vue'

export function useApi() {
  const api = inject(INJECTION_KEY)

  if (!api) {
    throw new Error('API is not available. Make sure to provide the API through injection.')
  }

  return api
}
