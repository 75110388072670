import type { ProductId, ProductRef } from 'src/models/product'
import { useArrayMap, useSessionStorage } from '@vueuse/core'
import type { Ref } from 'vue'

export type ViewedProductRef = {
  id: ProductId,
  ref: ProductRef
}

export function useViewedProductsStorage() {
  const items = useSessionStorage<ViewedProductRef[]>('viewedProducts', [])

  const addItem = (item: ViewedProductRef) => {
    items.value = items.value.find(i => i.id === item.id) ? items.value : [...items.value, item]
  }

  const removeItem = (item: ViewedProductRef) => {
    items.value = items.value.filter(i => i.id !== item.id)
  }

  const removeAll = () => {
    items.value = []
  }

  const productIdList: Ref<ProductId[]> = useArrayMap(items, (item) => item.id)

  const productRefList: Ref<ProductRef[]>  = useArrayMap(items, (item) => item.ref)

  return { items, productIdList, productRefList, addItem, removeItem, removeAll }
}
