import BaseModule from './base.module'

export type Report404Payload = {
  url: string
}

export default class ReportsModule extends BaseModule {
  async report404(payload: Report404Payload) {
    return this.client.post('/reports/404/', payload)
  }

  static key = 'reports' as const
}
