<template>
  <div class="wrapper full-width">
    <router-view />
  </div>
</template>

<script setup lang="ts">
import { useGoogleOneTapLogin, useGlobalMeta, useMetrics } from 'src/composables'

useGlobalMeta()
useMetrics()
useGoogleOneTapLogin()
</script>

<style lang="scss" scoped>

</style>
