import { defineStore } from 'pinia'
import type { UserKey, AuthId } from 'src/types/auth'
import type { AccessToken, RefreshToken, TokenSet } from 'src/types/token'
import { decodeToken } from 'src/utils/jwt'

interface State {
  token: AccessToken | undefined
  refreshToken: RefreshToken | undefined
  userKey: UserKey | undefined
  isAuth: boolean
}

export type AuthStore = ReturnType<typeof useAuthStore>

export const useAuthStore = defineStore('auth', {
  state: (): State => ({
    token: undefined,
    refreshToken: undefined,
    userKey: undefined,
    isAuth: false
  }),

  getters: {
    userId: (state) => {
      return state.token ? decodeToken(state.token).userId : undefined
    },
    authId(): AuthId | undefined {
      return this.userId || this.userKey
    }
  },

  actions: {
    setTokens({ access, refresh }: Partial<TokenSet>) {
      this.token = access
      this.refreshToken = refresh
    },
    removeTokens() {
      this.token = undefined
      this.refreshToken = undefined
    },
    setUserKey(userKey: UserKey) {
      this.userKey = userKey
    },
    removeUserKey() {
      this.userKey = undefined
    },
    setAuth(isAuth: boolean) {
      this.isAuth = isAuth
    }
  }
})
