import type { TransportCompany } from './models/delivery'
import type { NovaPoshtaCity } from './models/novaPoshta'
import type { ExternalLocale, Locale } from 'src/types'

export const LOCALES = [
  {
    id: 'uk',
    code: 'uk-UA',
    label: 'Укр',
    fileName: 'uk',
  },
  {
    id: 'ru',
    code: 'ru-RU',
    label: 'Рус',
    fileName: 'ru'
  }
] as Locale[]

export const DEFAULT_LOCALE = LOCALES[0]

export const EXTERNAL_LOCALES = [
  {
    id: 'en',
    code: 'en_US',
    label: 'Eng',
    url: 'https://gelius.ua/en/'
  }
] as ExternalLocale[]

export const BREAKPOINT = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280
}

export const DURATION = {
  1: 150,
  2: 300,
  3: 500,
  4: 1000
}

export const INPUT_DEBOUNCE = 500 //ms

export const TRANSPORT_COMPANY_ID = 8 as TransportCompany['id']
export const ONLINE_PAYMENT_ID = 1

export const PHONE_MASK = '(###) ###-##-##'

export const HIDDEN_PRICE_PLACEHOLDER = '🌸🌷🌼'

export const SUCCESS_PAGE_LIFETIME = 300000 //ms

export const DELIVERY_FINAL_TIME = 16 //HOURS

export const QUASAR_DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_FORMAT = 'dd.MM.yyyy'
export const DATE_MASK = '##.##.####'
export const DATE_MASK_PLACEHOLDER = '00.00.0000'

export const PAGE_ABOUT_BONUSES_URL = '/loyalty/'
export const PAGE_TERMS_URL = '/terms/'

export const MAIN_CITIES = [
  {
    id: '8d5a980d-391c-11dd-90d9-001a92567626',
    name: 'Київ'
  },
  {
    id: 'db5c88d0-391c-11dd-90d9-001a92567626',
    name: 'Одеса'
  },
  {
    id: 'db5c88f5-391c-11dd-90d9-001a92567626',
    name: 'Львів'
  },
  {
    id: 'db5c88f0-391c-11dd-90d9-001a92567626',
    name: 'Дніпро'
  },
  {
    id: 'db5c88e0-391c-11dd-90d9-001a92567626',
    name: 'Харків'
  }
] as {
  id: NovaPoshtaCity['ref'],
  name: string
}[]
