import BaseModule from './base.module'
import type { SendCodeResponseNormalized, SendCodeResponseRaw } from './code.module'
import { CodeModule } from './code.module'
import type { ProductRef } from 'src/models/product/index'
import type { PaymentInfoData } from 'src/models/order/paymentInfo'
import type { Merge } from 'type-fest'
import type { OrderId } from 'src/models/order'
import type { LocaleId, Nullable } from 'src/types'
import type { OrderPriceData } from 'src/models/order/price'
import type { Cart } from 'src/models/cart'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'

export type OneClickOrderPayload = Merge<ProductRef, {
  firstName: string
  phone: string
  phoneCode: string
  count: number
  comment?: string
}>

export type OneClickOrderResponse = {
  id: OrderId
  comment: Nullable<string>
  price: OrderPriceData
}

export type OneClickOrderValidatePhonePayload = Merge<ProductRef, {
  phone: string
}>

export type GetOrderPaymnetPayload = {
  orderId: string
}

export default class OrderModule extends BaseModule {
  async oneClickOrder(payload: OneClickOrderPayload) {
    return this.client.post<OneClickOrderResponse>('/order/one_click/', payload)
  }

  async oneClickOrderValidatePhone(phone: string): Promise<SendCodeResponseNormalized> {
    const res = await this.client.post<SendCodeResponseRaw>('/order/one_click/validate_phone/', { phone })
    return CodeModule.normalizeSendCodeResponse(res, phone)
  }

  async getPaymentInfo(orderId: OrderId) {
    return this.client.get<PaymentInfoData>(`/order/payment/info/${orderId}/`, { requiresAuth: true })
  }

  async getPayment(orderId: OrderId, paymentSystem = 'wayforpay') {
    return this.client.get<{ url: string }>(`/order/payment/${paymentSystem}/${orderId}/`, { requiresAuth: true })
  }

  async applyPromocode(promocode: string) {
    return this.client.post<Cart>('/order/promocode/', { promocode }, { requiresAuth: true })
  }

  static key = 'order' as const

  static queryKeys = createQueryKeys(this.key, {
    getPaymentInfo: (
      orderId: MaybeRef<string>,
      locale: MaybeRef<LocaleId>
    ) => [{ orderId, locale }]
  })
}
