import { inject } from 'vue'
import { INJECTION_KEY } from 'src/boot/eventBus'

export function useEventBus() {
  const eventBus = inject(INJECTION_KEY)

  if (!eventBus) {
    throw new Error('[EventBus] is not available. Make sure to provide the [EventBus] through injection.')
  }

  return eventBus
}
