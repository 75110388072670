import type { MetaOptions } from 'quasar/dist/types/meta'
import { useMeta } from 'quasar'
import { useRoute, useRouter } from 'vue-router'
import { useUrl } from '../helpers/url'
import { useI18n } from 'src/composables/useI18n'
import RouterService from 'src/services/router.service'
import { normalizeMediaUrl } from 'src/utils'
import { EXTERNAL_LOCALES, LOCALES } from 'src/config'
import { isExternalLocale } from 'src/utils/locale'
import { useNormalizeSeoMeta } from './useNormalizeSeoMeta'
import { useApiSiteGetInfoQuery } from 'src/composables/api/site'

export function useGlobalMeta() {
  const route = useRoute()

  const router = useRouter()

  const url = useUrl()

  const { locale } = useI18n()

  const { data: siteInfo } = useApiSiteGetInfoQuery()

  const normalizedSeoMeta = useNormalizeSeoMeta(() => {
    const { title, description, keyword, imageUrl } = siteInfo.value?.meta || {}

    return {
      title: title || undefined,
      description: description || undefined,
      keywords: keyword || undefined,
      image: imageUrl ? normalizeMediaUrl(imageUrl) : undefined
    }
  })

  useMeta(() => {
    const { title, meta: seoMeta } = normalizedSeoMeta.value

    const htmlAttr: MetaOptions['htmlAttr'] = {
      prefix: 'og: http://ogp.me/ns#'
    }

    const meta: MetaOptions['meta'] = (() => {
      const value: MetaOptions['meta'] = {
        ogSiteName: {
          property: 'og:site_name',
          content: process.env.DOMAIN,
        },
        ogType: {
          property: 'og:type',
          content: 'website',
        },
        ogUrl: {
          property: 'og:url',
          content: url.value,
        },
        ogLocale: {
          property: 'og:locale',
          content: locale.value,
        },
        ...seoMeta,
      }

      if (route.meta.robots || RouterService.hasQuery(route)) {
        value.robots = {
          name: 'robots',
          content: route.meta.robots || 'noindex, follow'
        }
      }

      return value
    })()

    const link: MetaOptions['link'] = (() => {
      const preconnect = {
        ['www.google.com']: { rel: 'preconnect', href: 'https://www.google.com' },
        ['stats.g.doubleclick.net']: { rel: 'preconnect', href: 'https://stats.g.doubleclick.net' },
      }

      const alternateLinks = Object.fromEntries([...LOCALES, ...EXTERNAL_LOCALES].map((locale) => {
        let href: string

        if (isExternalLocale(locale)) {
          href = locale.url
        } else {
          const routeLocationRaw = RouterService.changeRouteLocale(route, locale.id)
          const routeLocation = router.resolve(routeLocationRaw)
          href = RouterService.getUrl(routeLocation)
        }

        return [
          `alternate_${locale.id}`, {
            rel: 'alternate',
            hreflang: locale.code,
            href
          }
        ]
      }))

      const favicons: MetaOptions['link'] = (() => {
        const favicon = siteInfo.value?.meta.faviconUrl

        return {
          ...(favicon ? {
            favicon: {
              rel: 'icon',
              type: 'image/png',
              href: normalizeMediaUrl(favicon)
            }
          } : {})
        }
      })()

      return {
        ...preconnect,
        ...alternateLinks,
        ...favicons
      }
    })()

    return {
      htmlAttr,
      title,
      meta,
      link
    }
  })
}
