import type { LocaleId, PaginationDTO, PaginationResponse } from 'src/types'
import type { PromotionItem, PromotionDetail, PromotionSlug, PromotionGroupSlug, PromotionGroup } from 'src/models/promo'
import type { MaybeRef } from 'vue'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import { omit } from 'lodash'

export type GetPromotionsParams = PaginationDTO & {
  group?: PromotionGroupSlug
}

export class PromotionsModule extends BaseModule {
  async getPromotionDetail(slug: PromotionSlug, params?: PaginationDTO) {
    return this.client.get<PromotionDetail>(`/promos/${slug}/`, { params })
  }

  async getPromotions(payload?: GetPromotionsParams) {
    const url = payload?.group ? `/promos/groups/${payload.group}/` : '/promos/'
    const params = payload?.group ? omit(payload, ['group']) : payload

    return (await this.client.get<{
      results: PaginationResponse<PromotionItem>
    }>(url, { params })).results
  }

  async getPromotionGroups() {
    return this.client.get<PromotionGroup[]>('/promos/groups/')
  }

  static key = 'promotions' as const

  static queryKeys = createQueryKeys(this.key, {
    getPromotionDetail: (
      slug: MaybeRef<PromotionSlug | undefined>,
      params: MaybeRef<PaginationDTO | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ slug, params, locale }],
    getPromotions: (
      params: MaybeRef<GetPromotionsParams | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
    getPromotionGroups: (locale: MaybeRef<LocaleId>) => [{ locale }]
  })
}
