import BaseModule from './base.module'
import type { CodeSent, CodeResendFor } from 'src/types/code'

export type SendCodePayload = {
  username: string
  resendFor: CodeResendFor
}

export type SendCodeResponseRaw = Partial<{
  codeSent: boolean
  resendFor: CodeResendFor
  nextTry: number //minutes
}>

export type SendCodeResponseNormalized = CodeSent | undefined

export class CodeModule extends BaseModule {
  async resendCode(payload: SendCodePayload): Promise<SendCodeResponseNormalized> {
    const res = await this.client.post<SendCodeResponseRaw>('/code/resend/', payload)
    return CodeModule.normalizeSendCodeResponse(res, payload.username)
  }

  static key = 'code' as const

  static normalizeSendCodeResponse(res: SendCodeResponseRaw, phone: string): SendCodeResponseNormalized {
    if (!res.codeSent || !res.resendFor) return

    return {
      phone,
      resendFor: res.resendFor,
      resendTimeout: res.nextTry ? (res.nextTry * 1000 * 60) : undefined
    }
  }
}
