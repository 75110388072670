import type { LoginSocialProviderResponse } from 'src/types'
import { EventBus } from 'quasar'
import { boot } from 'quasar/wrappers'
import type { Cart } from 'src/models/cart'
import type { Category } from 'src/models/catalog/category'
import type { Order } from 'src/models/order'
import type { OneClickOrderInfo } from 'src/models/order/oneClickOrder'
import type { BaseProduct } from 'src/models/product'
import type { InjectionKey } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

declare module 'vue' {
  interface ComponentCustomProperties {
    $eventBus: EventBus<Callbacks>
  }
}

export type Callbacks = {
  'pageView': (route: RouteLocationNormalized) => void
  'pageView:category': (category: Category) => void
  'pageView:product': (product: BaseProduct) => void

  'notFound': (url: string) => void

  'cart:update': (cart: Cart) => void
  'cart:checkout': (cart: Cart) => void

  'purchase': (order: Order) => void

  'oneClickOrder:success': (order: OneClickOrderInfo) => void

  'product:click': (product: BaseProduct) => void
  'product:addToCart': (product: BaseProduct, quantity?: number) => void
  'product:removeFromCart': (product: BaseProduct, quantity?: number) => void
  'product:addToWishList': (product: BaseProduct) => void

  'search:request': (search: string) => void
  'search:notFound': (search: string) => void

  'googleOneTapLoginApprove': (data: LoginSocialProviderResponse) => void
}

export const INJECTION_KEY = Symbol('eventBus') as InjectionKey<EventBus<Callbacks>>

export default boot(({ app }) => {
  const eventBus = new EventBus<Callbacks>()

  app.config.globalProperties.$eventBus = eventBus

  app.provide(INJECTION_KEY, eventBus)
})
