import BaseModule from './base.module'
import type { SendCodeResponseNormalized, SendCodeResponseRaw } from './code.module'
import { CodeModule } from './code.module'
import type { TokenSet, WithTokenSet } from 'src/types/token'
import type { WithCodeSent } from 'src/types/code'
import type { Simplify } from 'type-fest'
import type { LoginSocialProviderResponse, SocialProvider } from 'src/types'

export type LoginPayload = {
  username: string
  password: string
}

export type LoginResponse = Simplify<Partial<WithTokenSet & WithCodeSent>>

export type LoginResponseRaw = Simplify<Partial<TokenSet & SendCodeResponseRaw>>

export type LoginApprovePayload = {
  phoneCode: string
}

export type RegistrationPayload = {
  firstName: string
  lastName: string
  username: string
  password: string
  email?: string
}

export type RegistrationApprovePayload = {
  phoneCode: string
}

export type FastRegistrationPayload = {
  firstName: string
  lastName: string
  phone: string
  email: string
}

export type FastRegistrationApprovePayload = FastRegistrationPayload & {
  phoneCode: string
}

export type CheckAccountExistencePayload = {
  username: string
}

export type ResetPasswordPayload = {
  username: string
}

export type ResetPasswordApprovePayload = {
  phoneCode: string
  password: string
  confirmedPassword: string
}

export type LoginBySocialProviderPayload = LoginSocialProviderResponse & {
  username?: string
  provider: SocialProvider
}

export type LoginBySocialProviderApprovePayload = LoginBySocialProviderPayload & {
  phoneCode: string
}

export default class AuthModule extends BaseModule {
  async login(payload: LoginPayload): Promise<LoginResponse> {
    const res = await this.client.post<LoginResponseRaw>('/login/', payload, { requiresUserKey: true })
    const { access, refresh } = res
    const tokenSet: TokenSet | undefined = (access && refresh) ? { access, refresh } : undefined
    const codeSent = CodeModule.normalizeSendCodeResponse(res, payload.username)
    return { tokenSet, codeSent }
  }

  async loginApprove(payload: LoginApprovePayload): Promise<TokenSet> {
    return this.client.post<TokenSet>('/login/code/', payload, { requiresUserKey: true })
  }

  async registration(payload: RegistrationPayload): Promise<SendCodeResponseNormalized> {
    const res = await this.client.post<SendCodeResponseRaw>('/registration/', payload, { requiresUserKey: true })
    return CodeModule.normalizeSendCodeResponse(res, payload.username)
  }

  async registrationApprove(payload: RegistrationApprovePayload): Promise<TokenSet> {
    return this.client.post<TokenSet>('/registration/approve/', payload, { requiresUserKey: true })
  }

  async fastRegistration(payload: FastRegistrationPayload): Promise<SendCodeResponseNormalized> {
    const res = await this.client.post<SendCodeResponseRaw>('/registration/order/', payload, { requiresUserKey: true })
    return CodeModule.normalizeSendCodeResponse(res, payload.phone)
  }

  async fastRegistrationApprove(payload: FastRegistrationApprovePayload): Promise<TokenSet> {
    return this.client.post<TokenSet>('/registration/order/approve/', payload, { requiresUserKey: true })
  }

  public async loginBySocialProvider(payload: LoginBySocialProviderPayload) {
    const res = await this.client.post<Partial<TokenSet & SendCodeResponseRaw>>('/auth/social/', payload, { requiresUserKey: true })
    const { access, refresh } = res
    const tokenSet: TokenSet | undefined = (access && refresh) ? { access, refresh } : undefined
    const codeSent = payload.username ? CodeModule.normalizeSendCodeResponse(res, payload.username) : undefined
    return { tokenSet, codeSent }
  }

  public loginBySocialProviderApprove(payload: LoginBySocialProviderApprovePayload) {
    return this.client.post<TokenSet>('/auth/social/approve/', payload, { requiresUserKey: true })
  }

  async checkAccountExistence(payload: CheckAccountExistencePayload): Promise<boolean> {
    return this.client.post<boolean>('/exists/', payload)
  }

  async resetPassword(payload: ResetPasswordPayload): Promise<SendCodeResponseNormalized> {
    const res = await this.client.post<SendCodeResponseRaw & { username: string }>('/reset_password/', payload)
    return CodeModule.normalizeSendCodeResponse(res, payload.username)
  }

  async resetPasswordApprove(payload: ResetPasswordApprovePayload) {
    await this.client.put('/reset_password/apply/', payload)
  }

  static key = 'auth' as const
}
