/* eslint-disable */
import logger from 'src/utils/logger'
import { useMeta } from 'quasar';
import { useEventBus } from 'src/composables/eventBus'

export function usePixel() {
  useMeta({
    script: {
      pixel: {
        innerHTML: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '1195893297731694');
          fbq('track', 'PageView');
        `
      }
    },
    noscript: {
      pixel: `
        <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=1195893297731694&ev=PageView&noscript=1"
        />
      `
    }
  })

  if (process.env.CLIENT) {
    const eventBus = useEventBus()

    const sendEvent = (event: string, payload: Record<string, unknown>) => {
      fbq('track', event, payload)
      logger.debug(`Pixel: ${event}`, payload)
    }

    eventBus.on('pageView:product', (product) => {
      sendEvent('ViewContent', {
        content_ids: product.id,
        content_type: 'product',
      })
    })

    eventBus.on('product:addToCart', (product) => {
      sendEvent('AddToCart', {
        value: product.price,
        currency: 'UAH',
        content_ids: product.id,
        content_type: 'product',
      })
    })

    eventBus.on('cart:checkout', (cart) => {
      sendEvent('InitiateCheckout', {
        value: cart.totalPrice,
        currency: 'UAH',
      })
    })

    eventBus.on('purchase', (order) => {
      sendEvent('Purchase', {
        value: order.price.total,
        currency: 'UAH',
      })
    })

    eventBus.on('oneClickOrder:success', (order) => {
      sendEvent('Purchase', {
        value: order.totalPrice,
        currency: 'UAH',
      })
    })
  }
}
