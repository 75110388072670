import type { SiteContacts } from 'src/models/site/contacts'
import type { BannerEntity, LocaleId } from 'src/types'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { Footer } from 'src/models/footer'
import type { SiteMeta } from 'src/models/site/meta'
import type { Submenu } from 'src/models/menu/submenu'
import type { CategoryTreeData } from 'src/models/catalog/categoryTree'
import type { MaybeRef } from 'vue'

export type SiteInfoResponse = {
  banners: {
    topHeaderBanner?: BannerEntity | null
    popupBanner?: BannerEntity | null
  }
  categories: CategoryTreeData
  footer: Footer,
  contacts: SiteContacts,
  meta: SiteMeta,
  submenu: Submenu,
}

export default class SiteModule extends BaseModule {
  async getInfo() {
    return this.client.get<SiteInfoResponse>('/site/info/')
  }

  static key = 'site' as const

  static queryKeys = createQueryKeys(this.key, {
    getInfo: (locale: MaybeRef<LocaleId>) => [{ locale }]
  })
}
