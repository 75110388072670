import AuthService from 'src/services/auth.service'
import { useStateStore } from 'src/stores/state'
import { useAuthStore } from 'src/stores/auth'
import { boot } from 'quasar/wrappers'
import type { Api } from 'src/api'
import { createApi } from 'src/api'
import type { InjectionKey } from 'vue'
import { markRaw } from 'vue'
import ApiService from 'src/services/api.service'

export const INJECTION_KEY = Symbol('api') as InjectionKey<Api>

declare module 'vue' {
  interface ComponentCustomProperties {
    $api: Api
  }
}

declare module 'pinia' {
  export interface PiniaCustomProperties {
    api: Api
  }
}

export default boot(({ app, store, router }) => {
  const apiClient = new ApiService({
    lang: () => app.config.globalProperties.$i18n.locale
  })

  const api = createApi(apiClient)

  if (process.env.CLIENT) {
    const queryClient = app.config.globalProperties.$queryClient
    const cookies = app.config.globalProperties.$cookies
    const authStore = useAuthStore(store)
    const stateStore = useStateStore(store)

    apiClient.setToken(() => authStore.token)
    apiClient.setRefreshToken(() => authStore.refreshToken)
    apiClient.setUserKey(() => authStore.userKey)
    apiClient.setRefreshFn((refreshToken) => api.tokens.getRefreshedTokens(refreshToken))

    apiClient.events.on('tokensRefreshed', (tokens) => {
      AuthService.setTokens(tokens, { authStore, cookies })
    })

    apiClient.events.on('tokensRefreshError', () => {
      AuthService.setLogout({ stateStore, authStore, router, queryClient, cookies })
    })
  }

  app.config.globalProperties.$api = api
  app.provide(INJECTION_KEY, api)

  store.use(({ store }) => {
    store.api = markRaw(api)
  })
})
