import type { RouteRecordRaw } from 'vue-router'
import { RouteName } from 'src/types/router'
import { LOCALES } from 'src/config'

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
    staticHeader?: boolean
    noBackground?: boolean
    robots?: string
  }
}

const Layout = () => import('src/layouts/TheLayout.vue')

const Main = () => import('src/pages/MainPage.vue')
const NotFoundPage = () => import('src/pages/NotFoundPage.vue')
const CatalogPage = () => import('src/pages/CategoryPage.vue')
const ProductPage = () => import('src/pages/product/ProductPage.vue')
const FlatPage = () => import('src/pages/FlatPage.vue')
/* blog */
const BlogPage = () => import('src/pages/blog/BlogPage.vue')
const BlogPostPage = () => import('src/pages/blog/BlogPostPage.vue')
const BlogGroupPage = () => import('src/pages/blog/BlogGroupPage.vue')
/* account */
const AccountLayout = () => import('src/pages/account/AccountLayout.vue')
const AccountBonuses = () => import('src/pages/account/AccountBonuses.vue')
/* const AccountComparison = () => import('src/pages/account/AccountComparison.vue') */
const AccountFavourites = () => import('src/pages/account/AccountFavourites.vue')
const AccountFeedbackAndQuestions = () => import('src/pages/account/AccountFeedbackAndQuestions.vue')
const AccountOrders = () => import('src/pages/account/AccountOrders.vue')
const AccountOrder = () => import('src/pages/account/AccountOrder.vue')
const AccountProfile = () => import('src/pages/account/AccountProfile.vue')
const AccountViewedProducts = () => import('src/pages/account/AccountViewedProducts.vue')
/* checkout */
const CheckoutStatus = () => import('src/pages/checkout/StatusPage.vue')

const routeLocales = Object.values(LOCALES)
  // .filter(({ id }) => id !== DEFAULT_LOCALE.id)
  .map(({ id }) => id)
  .join('|')

const routes: RouteRecordRaw[] = [
  {
    path: `/:locale(${routeLocales})?/`,
    children: [
      {
        path: '',
        component: Layout,
        children: [
          {
            path: '',
            sensitive: true,
            name: RouteName.Main,
            component: Main
          },
          {
            path: 'c/:slug/',
            name: RouteName.Category,
            component: CatalogPage
          },
          {
            path: 'p/:slug/',
            name: RouteName.Product,
            component: ProductPage,
            meta: { staticHeader: true }
          },
          {
            path: 'blog/',
            children: [
              {
                path: '',
                name: RouteName.Blog,
                component: BlogPage
              },
              {
                path: ':slug/',
                name: RouteName.BlogPost,
                component: BlogPostPage
              },
              {
                path: 'groups/:slug/',
                name: RouteName.BlogGroup,
                component: BlogGroupPage
              }
            ]
          },
          {
            path: 'checkout/status/:orderId',
            name: RouteName.CheckoutStatus,
            component: CheckoutStatus,
            meta: {
              requiresAuth: true,
              robots: 'noindex, nofollow'
            }
          },
          {
            path: 'account',
            name: RouteName.Account,
            component: AccountLayout,
            meta: {
              requiresAuth: true,
              robots: 'noindex, nofollow'
            },
            redirect: to => {
              return { name: RouteName.AccountProfile, params: to.params }
            },
            children: [
              {
                path: 'profile/',
                name: RouteName.AccountProfile,
                component: AccountProfile
              },
              {
                path: 'bonuses/',
                name: RouteName.AccountBonuses,
                component: AccountBonuses
              },
              /* {
                path: 'comparison/',
                name: RouteName.AccountComparison,
                component: AccountComparison
              }, */
              {
                path: 'favourites/',
                name: RouteName.AccountFavourites,
                component: AccountFavourites
              },
              {
                path: 'feedback-and-questions/',
                name: RouteName.AccountFeedbackAndQuestions,
                component: AccountFeedbackAndQuestions
              },
              {
                path: 'orders/',
                name: RouteName.AccountOrders,
                component: AccountOrders
              },
              {
                path: 'orders/:orderId/',
                name: RouteName.AccountOrder,
                component: AccountOrder
              },
              {
                path: 'viewed-products/',
                name: RouteName.AccountViewedProducts,
                component: AccountViewedProducts
              }
            ]
          },
          {
            path: 'search/',
            name: RouteName.Search,
            meta: {
              robots: 'noindex, nofollow'
            },
            component: () => import('src/pages/SearchPage.vue')
          },
          {
            path: 'promo/',
            name: RouteName.PromoIndex,
            component: () => import('src/pages/promo/PromoIndex.vue')
          },
          {
            path: 'promo/:slug/',
            name: RouteName.PromoShow,
            component: () => import('src/pages/promo/PromoShow.vue')
          },
          {
            path: ':slug/',
            name: RouteName.FlatPage,
            component: FlatPage
          },
          {
            path: ':pathMatch(.*)*',
            name: RouteName.NotFound,
            component: NotFoundPage
          }
        ]
      }
    ]
  }
]

/* debug */
if (process.env.DEBUGGING) {
  const debugRoute: RouteRecordRaw = {
    path: 'debug',
    name: 'debug',
    component: () => import('src/pages/DebugPage.vue')
  }

  routes[0].children?.unshift(debugRoute)
}

export default routes
