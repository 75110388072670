import type { TransportCompany, DeliveryTechnology } from 'src/models/delivery'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId, Nullable } from 'src/types'

export default class DeliveryModule extends BaseModule {
  async getTransportCompanies() {
    return this.client.get<TransportCompany[]>('/cart/transport_companys/')
  }

  async getDeliveryTechnologies(transportCompanyID: TransportCompany['id']) {
    return this.client.get<DeliveryTechnology[]>(`/cart/delivery_technologys/${transportCompanyID}/`)
  }

  static key = 'delivery' as const

  static queryKeys = createQueryKeys(this.key, {
    getTransportCompanies: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getDeliveryTechnologies: (
      transportCompanyID: MaybeRef<Nullable<TransportCompany['id']>>,
      locale: MaybeRef<LocaleId>
    ) => [{ transportCompanyID, locale }]
  })
}
