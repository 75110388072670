import type { ThanksPageData } from 'src/models/content/thanksPage'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId } from 'src/types'

export default class ContentModule extends BaseModule {
  async getMainPageBlocks() {
    return this.client.get<unknown>('/main_page_blocks/')
  }

  async getThanksPage() {
    return this.client.get<ThanksPageData>('/thanks_page/')
  }

  static key = 'content' as const

  static queryKeys = createQueryKeys(this.key, {
    getMainPageBlocks: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getThanksPage: (locale: MaybeRef<LocaleId>) => [{ locale }],
  })
}
