declare module 'axios' {
  export interface AxiosRequestConfig {
    skipRetry?: boolean
    requiresAuth?: boolean
    requiresUserKey?: boolean
  }
}

import type {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
} from 'axios'

import axios, { isAxiosError } from 'axios'
import humps from 'humps'
import EventEmitter from 'eventemitter3'
import type { AccessToken, RefreshToken, TokenSet } from 'src/types/token'
import type { UserKey } from 'src/types/auth'
import { cloneDeep, isArray, isPlainObject } from 'lodash'

export type ApiClientError = AxiosError<{
  detail?: string
  message?: string
}>

export type ApiServiceOptions = {
  userKey?: UserKey | (() => UserKey | undefined)
  token?: AccessToken | (() => AccessToken | undefined)
  refreshToken?: RefreshToken | (() => RefreshToken | undefined)
  lang?: string | undefined | (() => string | undefined)
  refreshFn?: (token: RefreshToken) => Promise<TokenSet>
}

function decamelizeData(data: unknown): unknown {
  if (!data) return data
  if (!(isArray(data) || isPlainObject(data))) return data
  if (data instanceof File) return data

  let result: any

  if (isArray(data)) {
    result = []

    for (let i = 0; i < data.length; i++) {
      result.push(decamelizeData(data[i]))
    }
  } else {
    result = {}

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof typeof data] as unknown
        result[humps.decamelize(key)] = decamelizeData(value)
      }
    }
  }

  return result
}
export default class ApiService {
  private axios: AxiosInstance

  public events: EventEmitter<{
    tokensRefreshed: (tokens: TokenSet) => void
    tokensRefreshError: () => void
  }>

  private _userKey?: ApiServiceOptions['userKey']

  private _token?: ApiServiceOptions['token']

  private _refreshToken?: ApiServiceOptions['refreshToken']

  private _lang?: ApiServiceOptions['lang']

  private refreshFn?: ApiServiceOptions['refreshFn']

  private refreshRequest?: Promise<void>

  constructor(options?: ApiServiceOptions) {
    this.axios = this.createAxios()
    this.events = new EventEmitter()
    this.setToken(options?.token)
    this.setRefreshToken(options?.refreshToken)
    this.setLang(options?.lang)
    this.setRefreshFn(options?.refreshFn)
  }

  public async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.get<T>(url, config)
    return response.data
  }

  public async post<T>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.post<T>(url, data, config)
    return response.data
  }

  public async put<T>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.put<T>(url, data, config)
    return response.data
  }

  public async patch<T>(url: string, data?: object, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.patch<T>(url, data, config)
    return response.data
  }

  public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.axios.delete<T>(url, config)
    return response.data
  }

  public setUserKey(userKey: ApiServiceOptions['userKey']) {
    this._userKey = userKey
  }

  public setToken(token: ApiServiceOptions['token']) {
    this._token = token
  }

  public setRefreshToken(token: ApiServiceOptions['refreshToken']) {
    this._refreshToken = token
  }

  public setLang(lang: ApiServiceOptions['lang']) {
    this._lang = lang
  }

  public setRefreshFn(fn: ApiServiceOptions['refreshFn']) {
    this.refreshFn = fn
  }

  private get userKey() {
    return typeof this._userKey === 'function' ? this._userKey() : this._userKey
  }

  private get token() {
    return typeof this._token === 'function' ? this._token() : this._token
  }

  private get refreshToken() {
    return typeof this._refreshToken === 'function' ? this._refreshToken() : this._refreshToken
  }

  private get lang() {
    return typeof this._lang === 'function' ? this._lang() : this._lang
  }

  private async refreshTokens() {
    if (!this.refreshToken || !this.refreshFn) {
      this.events.emit('tokensRefreshError')
      return
    }

    try {
      const tokens = await this.refreshFn(this.refreshToken)
      this.events.emit('tokensRefreshed', tokens)
    } catch (error) {
      this.events.emit('tokensRefreshError')
    }
  }

  private createAxios() {
    const apiClient = axios.create({
      baseURL: process.env.API_BASE_URL,
      headers: { 'Content-type': 'application/json' },
    })

    const decamelizeRequestInterceptor = (config: InternalAxiosRequestConfig) => {
      const newConfig = { ...config }

      newConfig.params = decamelizeData(newConfig.params)
      newConfig.data = decamelizeData(newConfig.data)

      return newConfig
    }

    const camelizeResponseInterceptor = (response: AxiosResponse) => {
      const newResponse = {
        ...response,
        data: response.data ? humps.camelizeKeys(response.data) : response.data,
      }

      return newResponse
    }

    const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
      const newConfig = { ...config }

      if (config.requiresAuth && this.token) {
        newConfig.headers.Authorization = `Bearer ${this.token}`
        return newConfig
      }

      if (config.requiresUserKey && this.userKey) {
        newConfig.headers['User-Key'] = this.userKey
        return newConfig
      }

      return newConfig
    }

    const errorResponseInterceptor = async (error: AxiosError) => {
      const { config, response } = error

      if (response?.data && !config?.skipRetry) {
        response.data = humps.camelizeKeys(response.data)
      }

      const isAuthError = config?.requiresAuth && response?.status === 401

      if (!isAuthError || config.skipRetry) {
        return Promise.reject(error)
      }

      if (!this.refreshRequest) {
        this.refreshRequest = this.refreshTokens()
      }

      await this.refreshRequest
      this.refreshRequest = undefined

      if (!this.token) {
        return Promise.reject(error)
      }

      const newRequest: AxiosRequestConfig = { ...error.config, skipRetry: true }

      return this.axios(newRequest)
    }

    const localeInterceptor = (config: InternalAxiosRequestConfig) => {
      if (!this.lang) return config

      const newConfig = cloneDeep(config)

      newConfig.params = { ...newConfig.params, lang: this.lang }

      return newConfig
    }

    apiClient.interceptors.request.use(localeInterceptor)

    apiClient.interceptors.request.use(decamelizeRequestInterceptor)

    apiClient.interceptors.request.use(authRequestInterceptor)

    apiClient.interceptors.response.use(
      camelizeResponseInterceptor,
      errorResponseInterceptor
    )

    return apiClient
  }

  static isApiError(error: unknown): error is ApiClientError {
    return isAxiosError(error)
  }
}
