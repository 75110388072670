/* eslint-disable */
import logger from 'src/utils/logger'
import { useMeta } from 'quasar';
import { useEventBus } from 'src/composables/eventBus'
import { BaseProduct } from 'src/models/product'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

const ID = 'GTM-5CGC7K6'

function getGtmProductInfo(productInfo: BaseProduct, quantity?: number) {
  return {
    item_id: productInfo.id,
    item_name: productInfo.name,
    item_category: productInfo.category,
    price: productInfo.price,
    quantity: quantity ?? 1
  }
}

export function useGtm() {
  useMeta({
    script: {
      gtm: {
        innerHTML: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${ID}');
        `
      }
    },
    noscript: {
      gtm: `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${ID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `
    }
  })

  if (process.env.CLIENT) {
    const eventBus = useEventBus()

    const sendEvent = (event: string, payload?: Record<string, unknown>) => {
      const { dataLayer } = window

      payload = { event, ...payload }

      dataLayer.push({ ecommerce: null })

      dataLayer.push(payload)

      logger.debug(`GTM: ${event}`, payload)
    }

    eventBus.on('product:click', (product) => {
      sendEvent('select_item', {
        ecommerce: {
          items: [getGtmProductInfo(product)]
        }
      })
    })

    eventBus.on('pageView:product', (product) => {
      sendEvent('view_item', {
        ecommerce: {
          items: [getGtmProductInfo(product)]
        }
      })
    })

    eventBus.on('product:addToCart', (product, quantity) => {
      sendEvent('add_to_cart', {
        ecommerce: {
          items: [getGtmProductInfo(product, quantity)]
        }
      })
    })

    eventBus.on('product:removeFromCart', (product, quantity) => {
      sendEvent('remove_from_cart', {
        ecommerce: {
          items: [getGtmProductInfo(product, quantity)]
        }
      })
    })

    eventBus.on('cart:checkout', (cart) => {
      sendEvent('begin_checkout', {
        ecommerce: {
          items: cart.list.items.map(item => {
            return getGtmProductInfo(item.baseProduct, item.count)
          })
        }
      })
    })

    eventBus.on('purchase', (order) => {
      sendEvent('purchase', {
        ecommerce: {
          transaction_id: order.id,
          value: order.price.total,
          currency: "UAH",
          items: order.productList.items.map(item => {
            return getGtmProductInfo(item.baseProduct, item.count)
          })
        }
      })
    })

    eventBus.on('oneClickOrder:success', (order) => {
      sendEvent('purchase', {
        ecommerce: {
          transaction_id: order.id,
          value: order.totalPrice,
          currency: "UAH",
          items: [
            getGtmProductInfo(order.product, order.productCount)
          ]
        }
      })
    })
  }
}
