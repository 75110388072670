import type { CategorySlug } from './../models/catalog/category'
import type { RouteParamsRaw } from 'vue-router'
import type { MergeDeep } from 'type-fest'
import type { BlogGroupSlug } from 'src/models/blog/group'
import type { PromotionSlug } from 'src/models/promo'
import type { OrderId } from 'src/models/order'
import type { ProductSlug } from 'src/models/product'
import type { BlogPostSlug } from 'src/models/blog/post'
import type { LocaleId } from 'src/types'

export enum RouteName {
  Main = 'main',
  Product = 'product',
  Category = 'category',
  FlatPage = 'flatPage',
  Blog = 'blog',
  BlogPost = 'blog.post',
  BlogGroup = 'blog.group',
  NotFound = 'notFound',
  CheckoutStatus = 'checkout.status',
  Account = 'account',
  AccountBonuses = 'account.bonuses',
  AccountOrders = 'account.orders',
  AccountOrder = 'account.order',
  AccountComparison = 'account.comparison',
  AccountFavourites = 'account.favourites',
  AccountPromoCodes = 'account.promocodes',
  AccountProductsAndBonuses = 'account.products-and-bonuses',
  AccountViewedProducts = 'account.viewed-products',
  AccountProfile = 'account.profile',
  AccountFeedbackAndQuestions = 'account.feedback-and-questions',
  Search = 'search',
  PromoIndex = 'PromoIndex',
  PromoShow = 'PromoShow',
}

export interface RouteParams {
  locale: LocaleId
}

export type CreateRouteParamsMap<
  T extends Partial<Record<RouteName, RouteParamsRaw>>
> = MergeDeep<Record<RouteName, RouteParams>, T>

export type RouteParamsMap = CreateRouteParamsMap<{
  [RouteName.Category]: { slug: CategorySlug }
  [RouteName.FlatPage]: { slug: string }
  [RouteName.Product]: { slug: ProductSlug }
  [RouteName.BlogPost]: { slug: BlogPostSlug }
  [RouteName.BlogGroup]: { slug: BlogGroupSlug }
  [RouteName.CheckoutStatus]: { orderId: OrderId }
  [RouteName.AccountOrder]: { orderId: OrderId }
  [RouteName.PromoShow]: { slug: PromotionSlug }
}>
