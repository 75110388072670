import type {
  RouteParamValue,
  RouteLocationNamedRaw,
  RouteLocationNormalized,
  LocationQueryRaw,
  RouteLocationNormalizedLoaded,
  LocationQueryValue
} from 'vue-router'
import type { RouteName, RouteParamsMap } from 'src/types/router'
import { isEmpty } from 'lodash'
import type { LocaleId, Nullable } from 'src/types'
import { localeToRouteLocale } from 'src/utils/locale'

export default class RouterService {
  static parseUTM(route: RouteLocationNormalized) {
    const utmParameters: Record<string, string> = {}

    for (const key in route.query) {
      if (key.startsWith('utm')) {
        utmParameters[key] = String(route.query[key])
      }
    }

    return utmParameters
  }

  static getRouteQuery(query?: LocationQueryValue | LocationQueryValue[]): LocationQueryValue {
    return (query && typeof query === 'object') ? query[0] : query ?? null
  }

  static getRouteTab(route: RouteLocationNormalizedLoaded): string | undefined {
    return RouterService.getRouteQuery(route.query.tab) ?? undefined
  }

  static getUrl(route: RouteLocationNormalizedLoaded) {
    return `${process.env.BASE_URL}${route.path}`
  }

  static changeRouteLocale(route: RouteLocationNormalized, locale: LocaleId | string): RouteLocationNamedRaw {
    const routeLocale = localeToRouteLocale(locale as LocaleId)

    return {
      name: route.name ?? undefined,
      params: { ...route.params, locale: routeLocale },
      query: route.query,
      hash: route.hash
    }
  }

  static getRouteParam<T extends string>(param: Nullable<RouteParamValue | RouteParamValue[]>): T | undefined {
    if (!param) return
    const value = typeof param !== 'string' ? param[0] : param
    return value as T
  }

  static getParams<T extends RouteName>(routeName: `${T}`, currentRoute: RouteLocationNormalizedLoaded): RouteParamsMap[T] | undefined {
    if (currentRoute.name !== routeName) return undefined
    return currentRoute.params as RouteParamsMap[T]
  }

  static hasQuery(route: RouteLocationNormalized) {
    return !isEmpty(route.query)
  }

  static getRouteLocation<K extends keyof RouteParamsMap>(
    routeName: `${K}`,
    params: RouteParamsMap[K],
    query?: LocationQueryRaw
  ) {
    const routeLocale = localeToRouteLocale(params.locale)

    return {
      name: routeName,
      params: { ...params, locale: routeLocale },
      query
    } as RouteLocationNamedRaw
  }
}
