import type { BigBannerItem } from 'src/models/banner/big-banner.model'
import type { TileBannerItem } from 'src/models/banner/tile-banner.model'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId } from 'src/types'

export class BannersModule extends BaseModule {
  async getMainBanner() {
    return this.client.get<BigBannerItem[]>('/banners/main_page/single/')
  }

  async getMainPageTileBanner() {
    return this.client.get<TileBannerItem[]>('/banners/main_page/tile/')
  }

  static key = 'banners' as const

  static queryKeys = createQueryKeys(this.key, {
    getMainBanner: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getMainPageTileBanner: (locale: MaybeRef<LocaleId>) => [{ locale }],
  })
}
