import { computed } from 'vue'
import { useRoute } from 'vue-router'

export function useUrl() {
  const route = useRoute()

  return computed(() => {
    return `${process.env.BASE_URL}${route.path}`
  })
}
