import { UserInfo } from 'src/models/account/userInfo'
import { useModel } from 'src/composables/model'
import { computed } from 'vue'
import { useAuthStore } from 'src/stores/auth'
import { storeToRefs } from 'pinia'
import { useApiAccountGetUserInfoQuery } from 'src/composables'

export function useAccount() {
  const authStore = useAuthStore()

  const userInfoQuery = useApiAccountGetUserInfoQuery()

  const userInfo = useModel(UserInfo, userInfoQuery.data)

  const { isAuth } = storeToRefs(authStore)

  const phone = computed(() => userInfo.value?.username)

  const firstName = computed(() => userInfo.value?.firstName)

  const lastName = computed(() => userInfo.value?.lastName)

  const secondName = computed(() => userInfo.value?.secondName)

  const fullName = computed(() => userInfo.value?.fullName)

  const wishlistProducts = computed(() => userInfo.value?.wishlistProducts ?? [])

  const viewedProducts = computed(() => userInfo.value?.viewedProducts ?? [])

  const preorderedProducts = computed(() => userInfo.value?.preorderedProducts ?? [])

  const isDemoAccess = computed(() => userInfo.value?.isDemoAccess)

  const email = computed(() => userInfo.value?.email)

  const usernameDisplay = computed(() => userInfo.value?.usernameDisplay)

  const bonuses = computed(() => userInfo.value?.bonuses)

  const isSeller = computed(() => !!userInfo.value?.isSeller)

  const connectedSocialProviders = computed(() => userInfo.value?.connectedSocialProviders ?? [])

  return {
    phone,
    firstName,
    lastName,
    secondName,
    fullName,
    usernameDisplay,
    isAuth,
    userInfo,
    isDemoAccess,
    email,
    wishlistProducts,
    viewedProducts,
    preorderedProducts,
    bonuses,
    isSeller,
    connectedSocialProviders
  }
}
