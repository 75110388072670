import type { Cookies } from 'quasar'
import log from 'src/utils/logger'

export type CookieServiceSetOptions = NonNullable<Parameters<Cookies['set']>['2']>
export type CookieServiceRemoveOptions = NonNullable<Parameters<Cookies['remove']>['1']>

export default class CookieService {
  private cookies: Cookies

  constructor(сookies: Cookies) {
    this.cookies = сookies
  }

  set(name: string, value: string, options?: CookieServiceSetOptions) {
    options = { ...CookieService.SET_OPTIONS_DEFAULTS, ...options }
    log.debug('Set Cookie', name, value, options)
    this.cookies.set(name, value, options)
  }

  get(name: string) {
    return this.cookies.get(name)
  }

  remove(name: string, options?: CookieServiceRemoveOptions) {
    options = { ...CookieService.REMOVE_OPTIONS_DEFAULTS, ...options }
    log.debug('Remove Cookie', name, options)
    this.cookies.remove(name, options)
  }

  static DEFAULTS: CookieServiceSetOptions = {
    path: '/',
    expires: '30d',
    sameSite: 'Lax',
  }

  static SET_OPTIONS_DEFAULTS: CookieServiceSetOptions = {
    path: this.DEFAULTS.path,
    expires: this.DEFAULTS.expires,
    sameSite: this.DEFAULTS.sameSite,
  }

  static REMOVE_OPTIONS_DEFAULTS: CookieServiceRemoveOptions = {
    path: this.DEFAULTS.path,
  }
}
