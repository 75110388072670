export const escapeChars: { [key: string]: string } = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '\'': '&#39;'
}

export function escapeHTML(str?: string): string | undefined {
  return str?.replace(/[&<>"']/g, (char) => escapeChars[char])
}

export function unescapeHTML(str?: string): string | undefined {
  return str?.replace(/&(amp|lt|gt|quot|#39);/g, (entity) => escapeChars[entity])
}
