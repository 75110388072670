import type { CategoryData } from 'src/models/catalog/category'
import type { TopCategoryItemData } from 'src/models/catalog/topCategories'
import type { ProductData, ProductIdList } from 'src/models/product'
import type { ProductGroupData } from 'src/models/catalog/productGroups'
import type { SearchResponseData, SearchCategoryResponseData } from 'src/models/catalog/search'
import type { PartialDeep } from 'type-fest'
import type { ProductRef } from 'src/models/product'
import type { SearchResultsData } from 'src/models/catalog/searchResults'
import BaseModule from './base.module'
import { createQueryKeys } from '@lukemorales/query-key-factory'
import type { MaybeRef } from 'vue'
import type { LocaleId } from 'src/types'

export type GetCategoryParams = any

export type GetProductGroupPayload = {
  groupID: string
}

export interface SearchParams {
  searchText: string
}

export interface SearchCategoryParams extends SearchParams {
  categoryId: string
  page: number
}

export default class CatalogModule extends BaseModule {
  async getCategory(slug: string, params?: GetCategoryParams) {
    return this.client.get<CategoryData>(`/c/${slug}/`, { params })
  }

  async getTopCategories() {
    return this.client.get<TopCategoryItemData[]>('/catalog/categories/top_sellers_categories/')
  }

  async postViewedProducts(payload: ProductRef[]) {
    return this.client.post('/catalog/viewed-products/', payload, { requiresAuth: true })
  }

  async deleteViewedProducts(payload?: ProductRef) {
    return this.client.delete('/catalog/viewed-products/', {
      data: payload,
      requiresAuth: true,
      requiresUserKey: true
    })
  }

  async getProductList(productIdList: ProductIdList) {
    return this.client.get<ProductData[]>('/catalog/products_by_ids/', {
      params: { products: String(productIdList) }
    })
  }
  async getProductGroups() {
    return this.client.get<ProductGroupData[]>('/catalog/stickers/')
  }
  async search(params: SearchParams) {
    return this.client.get<PartialDeep<SearchResponseData>>('/catalog/search/', {
      params: { ...params, responseType: 'partition' }
    })
  }

  async searchCategory(params: SearchCategoryParams) {
    return this.client.get<PartialDeep<SearchCategoryResponseData>>('/catalog/search/', {
      params: { ...params, responseType: 'partition' }
    })
  }

  async getSearchResults(params: GetCategoryParams) {
    return this.client.get<SearchResultsData>('/catalog/search/result/', { params })
  }

  static key = 'catalog' as const

  static queryKeys = createQueryKeys(this.key, {
    getCategory: (
      slug: MaybeRef<string | undefined>,
      params: MaybeRef<GetCategoryParams | undefined>,
      locale: MaybeRef<LocaleId>
    ) => [{ slug, params, locale }],
    getTopCategories: (locale: MaybeRef<LocaleId>) => [{ locale }],
    getProductList: (
      items: MaybeRef<ProductIdList>,
      locale: MaybeRef<LocaleId>
    ) => [{ items, locale }],
    getProductGroups: (locale: MaybeRef<LocaleId>) => [{ locale }],
    search: (
      params: MaybeRef<SearchParams>,
      locale: MaybeRef<LocaleId>
     ) => [{ params, locale }],
    searchCategory: (
      params: MaybeRef<SearchCategoryParams>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
    getSearchResults: (
      params: MaybeRef<GetCategoryParams>,
      locale: MaybeRef<LocaleId>
    ) => [{ params, locale }],
  })
}
