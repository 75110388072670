import type { LoginBySocialProviderPayload } from 'src/api/modules/auth.module'
import { useMutation } from '@tanstack/vue-query'
import { useApi } from 'src/composables'

export function useApiAuthLoginBySocialProviderMutation() {
  const api = useApi()

  return useMutation({
    mutationFn: (payload: LoginBySocialProviderPayload) => api.auth.loginBySocialProvider(payload)
  })
}
